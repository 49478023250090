import { useChatStickyChild } from '../DandyChat/ChatSticky.provider';
import type { TimelineEventAction, TimelineEventProps } from '../DandyChat/DandyChat.types';
import { DandyChatAttachment } from '../DandyChat/DandyChatAttachment';
import { format_timeline_date } from '../DandyChat/formatTimelineDate';
import { NotificationLogsEmailDialog } from '../NotificationLogs/NotificationLogsEmailDialog';
import { NotificationLogsSmsDialog } from '../NotificationLogs/NotificationLogsSmsDialog';
import { useSession } from '@orthly/session-client';
import type { ButtonProps } from '@orthly/ui-primitives';
import {
    FlossPalette,
    useScreenIsMobile,
    createStyles,
    makeStyles,
    Grid,
    Tooltip,
    Text,
    Button,
    Typography,
} from '@orthly/ui-primitives';
import Linkify from 'linkify-react';
import moment from 'moment';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            color: FlossPalette.BLACK,
        },
        eventDate: {
            color: FlossPalette.GRAY,
        },
        actionButton: {
            color: FlossPalette.STAR_GRASS,
            padding: 4,
            minWidth: 'unset',
            fontSize: 12,
        },
        avatarEvent: {
            background: FlossPalette.WHITE,
            borderRadius: 8,
            color: FlossPalette.GRAY,
            width: 24,
            height: 24,
        },
        subtitle: {
            paddingRight: 10,
            whiteSpace: 'pre-line',
        },
        addedBy: {
            marginLeft: 32,
            fontWeight: 500,
        },
        eventAttachment: {
            padding: 10,
            borderRadius: 5,
            margin: 5,
            backgroundColor: FlossPalette.WHITE,
        },
        timelineBorderLeft: {
            paddingLeft: 16,
            marginLeft: 12,
            borderLeft: `1px solid ${FlossPalette.DARK_TAN}`,
        },
        timelineItem: {
            paddingLeft: 32,
        },
    }),
);

type ButtonPropsBase = Omit<ButtonProps, 'variant'>;

const EventAction: React.FC<TimelineEventAction> = props => {
    const classes = useStyles();
    const buttonProps = React.useMemo<ButtonPropsBase>(() => {
        return {
            ...props.ButtonProps,
            href: props.type === 'link' ? props.href : undefined,
            target: props.type === 'link' ? '_blank' : undefined,
            className: `${classes.actionButton} ${props.ButtonProps?.className ?? ''}`,
            onClick: props.type === 'button' ? () => props.onClick() : undefined,
        };
    }, [classes.actionButton, props]);
    return (
        <Button {...buttonProps} variant={'ghost'} style={{ padding: 4 }}>
            {props.text}
        </Button>
    );
};

export const TimelineDate: React.FC<{ date: Date }> = props => {
    const { date } = props;
    const text = format_timeline_date(date);
    const ref = React.useRef<HTMLDivElement | null>(null);
    useChatStickyChild(ref, date);
    return (
        <Grid container style={{ paddingBottom: 16 }} ref={ref}>
            <Text style={{ fontSize: 12, fontWeight: 500, color: FlossPalette.GRAY }}>{text}</Text>
        </Grid>
    );
};

export type TimelineActor = {
    actor: string;
    actor_id: string | null;
    user_email: string | null;
    user_display_text: string | null;
};

export function generateTimelineActorText(actor: TimelineActor, withColon: boolean = false): string | undefined {
    switch (actor.actor) {
        case 'user':
        case 'staff':
            return `Action performed by${withColon ? ':' : ''} ${actor.user_display_text || actor.user_email}`;
        case 'service':
            return `Action performed by${withColon ? ':' : ''} ${actor.actor_id}`;
        case 'unknown':
        default:
            return undefined;
    }
}

export const TimelineEventDivider: React.FC<{ displayBorderLeft?: boolean }> = props => {
    const classes = useStyles();
    return (
        <Grid
            container
            style={{ paddingTop: 16 }}
            className={props.displayBorderLeft ? classes.timelineBorderLeft : undefined}
        >
            <Grid container style={{ borderTop: `1px solid ${FlossPalette.DARK_TAN}`, marginBottom: '8px' }} />
        </Grid>
    );
};

export const TimelineEvent: React.FC<TimelineEventProps> = props => {
    const classes = useStyles();
    const {
        date,
        subtitle,
        actionBy,
        action,
        actor,
        previousDate,
        attachments,
        setSelectedAttachmentPreview,
        eventType,
        notificationLogId,
    } = props;
    const currentTimelineDate = moment(date).format('MM/DD/YY');
    const previousTimelineDate = previousDate ? moment(previousDate).format('MM/DD/YY') : undefined;

    const displayDate = moment(date).format('h:mmA');
    const tooltipDate = moment(date).format('dddd, MMM D, YYYY h:mmA');

    const isMobile = useScreenIsMobile();
    const ref = React.useRef<HTMLDivElement | null>(null);
    useChatStickyChild(ref, date);
    const isZendeskNote = eventType === 'zendeskNote';

    const session = useSession();
    const isInternal = session?.organization_type === 'internal';
    const isEmail = typeof props.title === 'string' && props.title.includes('[email]');
    const isSms = typeof props.title === 'string' && props.title.includes('[sms]');

    return (
        <>
            {previousDate && <TimelineEventDivider />}
            <Grid container justifyContent={'center'} className={classes.root} ref={ref}>
                <Grid container alignItems={'center'} {...props.ContainerProps}>
                    {(!previousTimelineDate || currentTimelineDate !== previousTimelineDate) && (
                        <TimelineDate date={date} />
                    )}
                    <Grid container justifyContent={'space-between'} wrap={'nowrap'} alignItems={'center'}>
                        <Grid container wrap={'nowrap'} alignItems={'center'} style={{ paddingLeft: 'inherit' }}>
                            <Text variant={'body2'} style={{ fontWeight: isZendeskNote ? 'bold' : 500, marginLeft: 8 }}>
                                {props.title}
                            </Text>
                        </Grid>
                        <Tooltip title={tooltipDate} placement={'left'}>
                            <Typography
                                variant={'caption'}
                                color={'inherit'}
                                style={{ whiteSpace: 'nowrap' }}
                                className={classes.eventDate}
                            >
                                {displayDate}
                            </Typography>
                        </Tooltip>
                    </Grid>
                    {!!actionBy && (
                        <Text className={classes.addedBy} variant={'caption'}>
                            {actionBy}
                        </Text>
                    )}
                    {(!!subtitle || !!action || !!actor || !!attachments) && (
                        <Grid
                            container
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            className={classes.timelineItem}
                        >
                            <Grid container item xs={isZendeskNote ? 12 : 6} alignItems={'center'}>
                                <Text className={classes.subtitle} variant={'caption'}>
                                    <Linkify options={{ defaultProtocol: 'https', target: '_blank' }}>
                                        {subtitle}
                                    </Linkify>
                                </Text>
                            </Grid>
                            {actor && (
                                <Grid container item xs={8} alignItems={'center'}>
                                    <Text className={classes.subtitle} variant={'caption'}>
                                        {actor ?? ''}
                                    </Text>
                                </Grid>
                            )}
                            {action && !isMobile && (
                                <Grid container item xs={4} alignItems={'center'} justifyContent={'flex-end'}>
                                    <EventAction {...action} />
                                </Grid>
                            )}
                            {isEmail && notificationLogId && isInternal && (
                                <Grid container item xs={4} alignItems={'center'} justifyContent={'flex-end'}>
                                    <NotificationLogsEmailDialog
                                        notificationLogId={notificationLogId}
                                        disableButton={false}
                                    />
                                </Grid>
                            )}
                            {isSms && notificationLogId && isInternal && (
                                <Grid container item xs={4} alignItems={'center'} justifyContent={'flex-end'}>
                                    <NotificationLogsSmsDialog
                                        notificationLogId={notificationLogId}
                                        disableButton={false}
                                    />
                                </Grid>
                            )}
                            {attachments && attachments.length > 0 && (
                                <Grid container xs={12} alignItems={'center'} className={classes.eventAttachment}>
                                    {attachments.map(({ url, description }) => (
                                        <DandyChatAttachment
                                            setSelectedAttachmentPreview={setSelectedAttachmentPreview}
                                            attachment_url={url}
                                            description={description}
                                            fromCurrentUser={false}
                                            key={url}
                                        />
                                    ))}
                                </Grid>
                            )}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </>
    );
};
