import * as ScanResultList from './ScanResultList.vm';
import _ from 'lodash';
import React from 'react';

export type ScanResult =
    | {
          id: string;
          bookingAction: string;
          bookingTime: Date;
          orderNumber: string;
          result: 'SUCCESS';
          stack: string;
          stage: string;
      }
    | {
          id: string;
          result: 'ERROR';
      };

export function addScanResults(scanResults: ScanResult[], toAdd: ScanResult[]): ScanResult[] {
    const sortDesc = (a: Date, b: Date) => b.getTime() - a.getTime();

    const combined = scanResults.concat(toAdd);
    const error = combined.filter(i => i.result === 'ERROR');
    const uniqueError = _.uniqBy(error, 'id');
    const success = combined.filter(i => i.result === 'SUCCESS');
    const uniqueSuccess = _.uniqWith(success, (a, b) => a.id === b.id && a.stage === b.stage);
    const sortedSuccess = uniqueSuccess.sort((a, b) => sortDesc(a.bookingTime, b.bookingTime));
    // Errors should always be visible at the top.
    return [...uniqueError, ...sortedSuccess];
}

export function clearScanResults(): ScanResult[] {
    return [];
}

/**
 * Make a unique grid item key from a scan result.
 *
 * @remarks
 *
 * Success results should return both the order id and stage name,
 * to avoid accidental duplicates in the grid.
 *
 * @param result The scan result to make a key for.
 * @returns A unique key for the scan result.
 */
export function makeGridItemKey(result: ScanResult) {
    if (result.result === 'ERROR') {
        return result.id;
    }
    return `${result.id}-${result.stage}`;
}

export type ReduceScanResultsAction = { type: 'ADD'; values: ScanResult[] } | { type: 'CLEAR' };

export function reduceScanResults(scanResults: ScanResult[], action: ReduceScanResultsAction): ScanResult[] {
    if (action.type === 'ADD') {
        return ScanResultList.addScanResults(scanResults, action.values);
    }
    if (action.type === 'CLEAR') {
        return ScanResultList.clearScanResults();
    }
    throw new Error('Invalid action for reduceScanResults.');
}

export function useViewModel() {
    const [scanResults, dispatchScanResults] = React.useReducer(reduceScanResults, []);

    const addScanResults = (v: ScanResult[]) => dispatchScanResults({ type: 'ADD', values: v });
    const clearScanResults = () => dispatchScanResults({ type: 'CLEAR' });

    return { addScanResults, clearScanResults, scanResults };
}
