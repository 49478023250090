import type { LabsGqlFilterCriteriaSubmissionInput } from '@orthly/graphql-schema';
import type { LabsGqlFilterComparator } from '@orthly/graphql-schema';
import type { MaterialUiPickersDate } from '@orthly/ui';
import { SimpleDatePicker } from '@orthly/ui';
import { Grid, Button, FilterIcon, Popover } from '@orthly/ui-primitives';
import moment from 'moment/moment';
import React from 'react';

export interface LabPortalOrderListDateFilterTagProps {
    currentFilterValue: Date | null;
    addFilter: (filter: LabsGqlFilterCriteriaSubmissionInput) => void;
    title: string;
    filterId: string;
    comparator: LabsGqlFilterComparator;
}

function LabPortalOrderListDateFilterTag({
    currentFilterValue,
    addFilter,
    title,
    filterId,
    comparator,
}: LabPortalOrderListDateFilterTagProps) {
    const [filterTagOpen, setFilterTagOpen] = React.useState(false);
    const [filterValue, setFilterValue] = React.useState<MaterialUiPickersDate>(currentFilterValue);
    const anchorRef = React.useRef<HTMLButtonElement | null>(null);

    return (
        <Grid>
            <Button
                ref={anchorRef}
                variant={'secondary'}
                onClick={() => setFilterTagOpen(filterTagOpen => !filterTagOpen)}
            >
                <FilterIcon /> {title}
                {filterValue ? `: ${moment(filterValue).format('MMM D')}` : ''}
            </Button>
            <Popover
                anchorEl={anchorRef.current}
                open={filterTagOpen}
                PaperProps={{
                    style: { width: '200px' },
                }}
                onClose={() => setFilterTagOpen(false)}
            >
                <SimpleDatePicker
                    onChange={date => {
                        setFilterValue(date);
                        setFilterTagOpen(false);
                        addFilter({
                            filter_id: filterId,
                            comparator: comparator,
                            comparison_value: moment(date).format('YYYY/MM/DD'),
                        });
                    }}
                    value={(filterValue ?? '') as MaterialUiPickersDate}
                    label={''}
                />
            </Popover>
        </Grid>
    );
}

export default React.memo(LabPortalOrderListDateFilterTag);
