import { useGetEmailPreviewByNotificationLogId } from './graphql/useGetEmailPreviewByNotificationLogId.graphql';
import { RootActionDialog } from '@orthly/ui';
import { FlossPalette, IconButton, Tooltip, Icon, Text } from '@orthly/ui-primitives';
import React from 'react';

export const NotificationLogsEmailDialog: React.VFC<{ notificationLogId: string; disableButton: boolean }> = ({
    notificationLogId,
    disableButton,
}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const { emailTemplate } = useGetEmailPreviewByNotificationLogId(notificationLogId, !open);

    return (
        <RootActionDialog
            loading={false}
            title={'Email Preview'}
            subtitle={
                <div style={{ display: 'flex' }}>
                    <Icon icon={'Error'} style={{ color: FlossPalette.ATTENTION, marginRight: '4px' }} />
                    <Text variant={'body2'}>
                        NEVER click on buttons or links directly. Always right click and copy the link, and open it in a
                        chrome incognito window, as a lot of them are impersonation links.
                    </Text>
                    <Icon icon={'Error'} style={{ color: FlossPalette.ATTENTION, marginLeft: '4px' }} />
                </div>
            }
            CustomButton={({ onClick }) => (
                <Tooltip title={'Email preview'}>
                    <IconButton onClick={onClick} style={{ color: FlossPalette.STAR_GRASS }} disabled={disableButton}>
                        <Icon
                            icon={'Email'}
                            style={{ color: disableButton ? FlossPalette.GRAY : FlossPalette.PRIMARY_FOREGROUND }}
                        />
                    </IconButton>
                </Tooltip>
            )}
            setOpen={setOpen}
            open={open}
            maxWidth={'lg'}
            content={<div dangerouslySetInnerHTML={{ __html: emailTemplate ?? '' }} />}
            showCloseButton
        />
    );
};
