import { type ScannedOrderDetails } from '../hooks/useScannedOrderDetailsQuery.graphql';
import { ScannedOrderDetailRow } from './ScannedOrderDetailRow';
import { List } from '@orthly/ui-primitives';
import React from 'react';

export const ScannedOrdersList: React.VFC<{
    scannedOrders: ScannedOrderDetails[];
    setScannedOrders: (scannedOrders: ScannedOrderDetails[]) => void;
}> = ({ scannedOrders, setScannedOrders }) => {
    return (
        <List>
            <h1>SCANNED ORDERS</h1>
            {scannedOrders.map(o => {
                return (
                    <ScannedOrderDetailRow
                        key={o.orderId}
                        order={o}
                        scannedOrders={scannedOrders}
                        setScannedOrders={setScannedOrders}
                    />
                );
            })}
        </List>
    );
};
