import type { ActionsProp } from '../contexts/ActionsContext';
import { CustomActionsContextProvider } from '../contexts/ActionsContext';
import { TableDataContextProvider } from '../contexts/TableDataContext';
import { TableOptionsContextProvider } from '../contexts/TableOptionsContext';
import type { MUITableStateInitialProp } from '../state/TableStateContext';
import { TableStateProvider } from '../state/TableStateContext';
import type {
    Column,
    DisplayOptions,
    EventHookOptions,
    MUIDataObj,
    PaginationOptions,
    RowOptions,
    ToolbarOptions,
    TranslationOptions,
} from '../types';
import { MUITableLayout } from './MUITableLayout';
import React from 'react';

export interface MUITableBaseProps<R extends MUIDataObj> {
    title: string;
    loading?: boolean;
    DetailPanel?: React.ComponentType<{ data: R }>;
    toolbarOptions?: ToolbarOptions;
    rowOptions?: RowOptions<R>;
    paginationOptions?: PaginationOptions;
    translationOptions?: Partial<TranslationOptions>;
    displayOptions?: DisplayOptions;
}

export interface MUITableProps<R extends MUIDataObj = MUIDataObj> extends MUITableBaseProps<R> {
    columns: Column<R>[];
    data: R[];
    actions?: ActionsProp<R>;
    eventHooks?: EventHookOptions<R>;
    initialState?: MUITableStateInitialProp;
}

export function MUITableRoot<R extends MUIDataObj>(props: MUITableProps<R>) {
    const { columns, data, eventHooks, actions, ...options } = props;
    const defaultSortColumn = columns.find(c => !!c.defaultSort);
    return (
        <TableStateProvider
            initialState={props.initialState}
            columns={columns}
            hookOptions={props.eventHooks || {}}
            paginationOptions={props.paginationOptions || {}}
            hiddenColumnNames={props.columns.flatMap(c => (c.hidden ? [c.name] : []))}
            sortColumn={
                defaultSortColumn
                    ? { asc: defaultSortColumn.defaultSort === 'asc', columnName: defaultSortColumn.name }
                    : undefined
            }
        >
            <TableOptionsContextProvider {...options}>
                <CustomActionsContextProvider actions={actions}>
                    <TableDataContextProvider columns={props.columns} data={props.data} loading={!!props.loading}>
                        <MUITableLayout loading={!!props.loading} columns={props.columns} />
                    </TableDataContextProvider>
                </CustomActionsContextProvider>
            </TableOptionsContextProvider>
        </TableStateProvider>
    );
}
