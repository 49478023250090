import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { PracticeScreen } from '@orthly/dentin';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { LabsGqlLabOrderWaxupReviewStatus } from '@orthly/graphql-schema';
import { ChevronRight } from '@orthly/ui';
import {
    Button,
    FlossPalette,
    Text,
    stylesFactory,
    useScreenIsMobileOrVerticalTablet,
    Grid,
} from '@orthly/ui-primitives';
import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

const useStyles = stylesFactory(() => ({
    root: {
        backgroundColor: FlossPalette.ATTENTION_BACKGROUND,
        height: 40,
        width: '100%',
        padding: '8px 16px',
    },
    button: {
        height: 24,
    },
}));

interface GuidedWaxupAlertBannerProps {
    order: Pick<LabsGqlOrder, 'id' | 'waxup_status'>;
}

export const GuidedWaxupAlertBanner: React.VFC<GuidedWaxupAlertBannerProps> = ({ order }) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const isMobile = useScreenIsMobileOrVerticalTablet();

    if (order.waxup_status !== LabsGqlLabOrderWaxupReviewStatus.ReadyForReview) {
        return null;
    }

    return (
        <Grid container className={classes.root}>
            <Grid item xs>
                <Text variant={'body2'} medium color={'ATTENTION_FOREGROUND'}>
                    {isMobile ? `Design ready!` : `Your patient's design preview is ready! Please review this design.`}
                </Text>
            </Grid>
            <Grid item>
                <Button
                    variant={'ghost'}
                    className={classes.button}
                    onClick={() => {
                        BrowserAnalyticsClientFactory.Instance?.track('Button Clicked', {
                            AssetName: 'Guided Waxup Button - Open Design Preview',
                            AssetType: 'banner',
                            AssetVersion: '',
                            AssetCTAText: 'Open Design Preview',
                        });
                        navigate(`/${PracticeScreen.guided_waxup}/${order.id}`, { replace: true });
                    }}
                >
                    Open Design Preview <ChevronRight />
                </Button>
            </Grid>
        </Grid>
    );
};
