import { usePanLabels } from '../../../../utils/EnabledFeaturesForLab';
import { useManufacturerIdFromSession } from '../../../../utils/authorization';
import { LabPortalErrorPage } from '../../../LabPortalErrorPage';
import { LabPortalOrdersPage } from './LabPortalOrdersPage';
import { INTAKE_WITH_DESIGN_ROUTE, INTAKE_WITHOUT_DESIGN_ROUTE } from './LabPortalRoutes';
import type { TabOption } from './components/LabPortalOrderListTabs/LabPortalOrderListTabs';
import { LabPortalIntakeViewedDocsListView } from './components/LabPortalOrderListViews/intake/LabPortalIntakeViewedDocsListView';
import { LabPortalIntakeWithDesignListView } from './components/LabPortalOrderListViews/intake/LabPortalIntakeWithDesignListView';
import { LabPortalIntakeWithReturnListView } from './components/LabPortalOrderListViews/intake/LabPortalIntakeWithReturnListView';
import { LabPortalIntakeWithoutDesignListView } from './components/LabPortalOrderListViews/intake/LabPortalIntakeWithoutDesignListView';
import { LabPortalNewNeedsManualEntryListView } from './components/LabPortalOrderListViews/new/LabPortalNewNeedsManualEntryListView';
import { LabPortalOrderTabCountsCtxProvider } from './components/providers/LabPortalOrderTabCountsProvider';
import { convertFilterFromHumanReadableQueryParams } from './utils/QueryParamConversionUtils';
import type { LabsGqlLabPortalIntakeOrderListCountsFragment } from '@orthly/graphql-operations';
import { useLabPortalIntakeOrderCountsQuery } from '@orthly/graphql-react';
import { LabsGqlLabPortalLabOrderSortKey } from '@orthly/graphql-schema';
import { OrderFilterIdEnum } from '@orthly/shared-types';
import { useQueryParam } from '@orthly/ui';
import { compact } from 'lodash';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

const TabOptions: TabOption[] = [
    {
        label: 'Intake with Design',
        tabId: 'with-design',
        pathValue: INTAKE_WITH_DESIGN_ROUTE,
        countsQueryKey: 'intakeWithDesign',
    },
    {
        label: 'Intake without Design',
        tabId: 'without-design',
        pathValue: INTAKE_WITHOUT_DESIGN_ROUTE,
        countsQueryKey: 'intakeWithoutDesign',
    },
    {
        label: 'Intake with Return',
        tabId: 'with-return',
        pathValue: '/intake/with-return',
        countsQueryKey: 'intakeWithReturn',
    },
    {
        label: 'Intake - Needs Manual Entry',
        tabId: 'needs-manual-entry',
        pathValue: '/intake/needs-manual-entry',
        countsQueryKey: 'newManualEntryNeeded',
    },
    {
        label: 'Intake - Docs Viewed',
        tabId: 'docs-viewed',
        pathValue: '/intake/docs-viewed',
        countsQueryKey: 'intakeViewedDocs',
    },
];

export const LabPortalIntakeOrdersPage: React.VFC = () => {
    const [filterString, _setFilterString] = useQueryParam('filters');

    const { data: countData, refetch } = useLabPortalIntakeOrderCountsQuery({
        variables: {
            criteria: filterString ? convertFilterFromHumanReadableQueryParams(filterString) : [],
            sort: {},
            columns: TabOptions.map(opt => opt.countsQueryKey),
        },
    });

    const tabOptionsWithCounts = React.useMemo(
        () =>
            TabOptions.map(option => ({
                ...option,
                orderCount:
                    countData?.orders?.[
                        option.countsQueryKey as keyof Omit<LabsGqlLabPortalIntakeOrderListCountsFragment, '__typename'>
                    ],
            })),
        [countData],
    );

    const manufacturerId = useManufacturerIdFromSession();
    const showPanCodes = usePanLabels(manufacturerId);

    return (
        <LabPortalOrderTabCountsCtxProvider refetch={refetch}>
            <LabPortalOrdersPage
                title={'Orders for Intake'}
                tabOptions={tabOptionsWithCounts}
                customSortOptions={[
                    { label: 'Scheduled Start', value: LabsGqlLabPortalLabOrderSortKey.FabricationScheduledStartDate },
                    { label: 'Ship Date', value: LabsGqlLabPortalLabOrderSortKey.ShipByDate },
                    { label: 'Time in List', value: LabsGqlLabPortalLabOrderSortKey.TimeInList },
                ]}
                customFilterOptions={compact([
                    { label: 'Ship Date', value: 'ship_by_date' },
                    { label: 'Fabrication Start Date', value: OrderFilterIdEnum.fabrication_start_date },
                    showPanCodes && {
                        label: 'Pan Category',
                        value: OrderFilterIdEnum.lab_pan_category,
                    },
                ])}
            >
                <Switch>
                    <Route exact path={INTAKE_WITH_DESIGN_ROUTE}>
                        <LabPortalIntakeWithDesignListView />
                    </Route>
                    <Route exact path={INTAKE_WITHOUT_DESIGN_ROUTE}>
                        <LabPortalIntakeWithoutDesignListView />
                    </Route>
                    <Route exact path={'/intake/with-return'}>
                        <LabPortalIntakeWithReturnListView />
                    </Route>
                    <Route exact path={`/intake/needs-manual-entry`}>
                        <LabPortalNewNeedsManualEntryListView />
                    </Route>
                    <Route exact path={`/intake/docs-viewed`}>
                        <LabPortalIntakeViewedDocsListView />
                    </Route>
                    <Route>
                        <LabPortalErrorPage />
                    </Route>
                </Switch>
            </LabPortalOrdersPage>
        </LabPortalOrderTabCountsCtxProvider>
    );
};
