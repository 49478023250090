import { Button } from '@orthly/ui-primitives';
import React from 'react';

export const PrintShippingLabelButton: React.VFC<{
    unprintedLabelUrl: string;
    setLabelWasOpened: (wasOpened: boolean) => void;
}> = ({ unprintedLabelUrl, setLabelWasOpened }) => {
    const printShippingLabel = () => {
        window.open(unprintedLabelUrl, '_blank', 'noreferrer noopener');
        setLabelWasOpened(true);
    };

    return (
        <Button variant={'primary'} onClick={() => printShippingLabel()}>
            Print Shipping Label
        </Button>
    );
};
