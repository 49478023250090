import '../../../assets/styles/unboxing-slip.css';
import { ListAbutmentPartsProvider, useAbutmentPartsLoading } from '../../context';
import { PrintableSlip } from '../PrintableSlip';
import { PrintableSlipPreferences } from '../PrintableSlip/components/PrintableSlipPreferences';
import { PrintableSlipItem } from '../PrintableSlip/components/item/PrintableSlipItem';
import { PrintableSlipItemsSection } from '../PrintableSlip/components/item/PrintableSlipItemsSection';
import { PrintableSlipPage } from '../PrintableSlip/components/page/PrintableSlipPage';
import { PrintableSlipSectionDivider } from '../PrintableSlip/components/shared/PrintableSlipSectionDivider';
import { getNotesByCategory } from '../PrintableSlip/utils/notes.util';
import { UnboxingSlipHeader } from './components/header/UnboxingSlipHeader';
import { AdjustmentNotes } from './components/sections/AdjustmentNotes';
import { FeedbackQRCode } from './components/sections/FeedbackQRCode';
import { UnboxingSlipMiniItem } from './components/sections/UnboxingSlipMiniItem';
import type { LabsGqlLabOrderForLabSlipFragment } from '@orthly/graphql-operations';
import { useLabOrdersByIdForLabSlipsQuery } from '@orthly/graphql-react';
import { ErrorPage, WarningIcon } from '@orthly/ui';
import React from 'react';
import { useLocation } from 'react-router-dom';

// Helper to hide Lab Slip only preference fields
function transformOrderForUnboxingRender(
    order?: LabsGqlLabOrderForLabSlipFragment,
): LabsGqlLabOrderForLabSlipFragment | undefined {
    if (!order) {
        return undefined;
    }
    return {
        ...order,
        items_v2: order.items_v2.map(item => ({
            ...item,
            // Remove the "Lab Slip Maximum Model Element Height in mm" preference field
            preference_fields: item.preference_fields.filter(
                f => f.field_id !== 'lab-slip-maximum-model-element-height-in-mm',
            ),
        })),
    };
}

interface IUnboxingSlipProps {
    // letter is standard default, 2 column. 4x6 is for new packaging, printed using label printer
    layout?: '4x6' | 'letter';
    // The id of the order to render into Unboxing Slip
    orderId: string;
    // any custom logic after printing
    onPrintComplete?: () => void;
    customLoader?: React.ReactNode;
    onLoadComplete?: (orderData?: LabsGqlLabOrderForLabSlipFragment) => void;
}

export interface IUnboxingSlipDumbComponentProps {
    layout: '4x6' | 'letter';
    openPrintWindow: boolean;
    onPrintComplete?: () => void;
    order: LabsGqlLabOrderForLabSlipFragment;
}

export const UnboxingSlipDumbComponent: React.VFC<IUnboxingSlipDumbComponentProps> = ({
    layout,
    openPrintWindow,
    onPrintComplete,
    order,
}) => {
    return (
        <PrintableSlip
            // this is done to ensure the print window is opened again when the layout changes
            key={layout}
            openPrintWindow={openPrintWindow}
            onPrintComplete={onPrintComplete}
        >
            <div className={layout === '4x6' ? 'printable-slip-4x6' : undefined}>
                <PrintableSlipPage orderNumber={order.order_number} singleColumn={layout === '4x6'}>
                    <UnboxingSlipHeader order={order} layout={layout} />
                    <AdjustmentNotes notesToDoctor={getNotesByCategory(order.lab_slip_alerts).notesToDoctor} />
                    <PrintableSlipItemsSection
                        order={order}
                        ItemComponent={layout === '4x6' ? UnboxingSlipMiniItem : PrintableSlipItem}
                    />
                    {/* Hide the preferences section for label-size Unboxing Slip */}
                    {layout === '4x6' ? <PrintableSlipSectionDivider /> : <PrintableSlipPreferences order={order} />}
                    <FeedbackQRCode order={order} />
                </PrintableSlipPage>
            </div>
        </PrintableSlip>
    );
};

/**
 * Internal component used to ensure abutment part data is loaded before rendering the print window
 */
const AbutmentPartAwareUnboxingSlip: React.VFC<IUnboxingSlipProps> = ({
    orderId,
    onPrintComplete,
    customLoader,
    layout = 'letter',
    onLoadComplete,
}) => {
    const abutmentPartLoading = useAbutmentPartsLoading();
    const { data, loading, error } = useLabOrdersByIdForLabSlipsQuery({
        variables: { ids: [orderId] },
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            onLoadComplete?.(data?.orders[0]);
        },
    });

    const order = transformOrderForUnboxingRender(data?.orders[0]);

    if (loading) {
        return customLoader !== undefined ? <>{customLoader}</> : <h1>Loading...</h1>;
    }
    if (error || !order) {
        return <ErrorPage message={'Error'} text={'Something went wrong'} icon={WarningIcon} />;
    }

    return (
        <UnboxingSlipDumbComponent
            order={order}
            openPrintWindow={!loading && !abutmentPartLoading && !!data}
            onPrintComplete={onPrintComplete}
            layout={layout}
        />
    );
};

/**
 * Retrieves the relevant data for orders and renders order "Unboxing Slip"
 * that can be printed by the manufacturer and sent to the practice
 * @returns Rendered "Unboxing Slips"
 */
export const UnboxingSlip: React.VFC<IUnboxingSlipProps> = props => {
    return (
        <ListAbutmentPartsProvider>
            <AbutmentPartAwareUnboxingSlip {...props} />
        </ListAbutmentPartsProvider>
    );
};

/**
 * FIXME: Remove after manual testing is complete https://meetdandy.atlassian.net/browse/EPDSCM-253
 * Temporary route handler for rendering individual Unboxing Slip
 * during manual testing. Reads the "orderId" query param to load and
 * render the Unboxing Slip
 * @returns Rendered Unboxing Slip
 */
export const UnboxingSlipRoute: React.VFC = () => {
    const { search } = useLocation();
    const orderId = React.useMemo(() => new URLSearchParams(search).get('orderId'), [search]);
    if (orderId) {
        return <UnboxingSlip orderId={orderId} layout={search.includes('4x6') ? '4x6' : 'letter'} />;
    }
    return null;
};
