import { useCalculateContentHeight } from '../../../utils/LayoutUtils';
import { LabOrderChat } from '../components/LabOrderChat';
import { HORIZONTAL_NAV_HEIGHT } from '@orthly/dentin';
import type { LabsGqlChatMessageDtoFragment, LabsGqlOrder } from '@orthly/graphql-operations';
import type { SingleLabOrderQueryHookResult } from '@orthly/graphql-react';
import { useOrderRefetch } from '@orthly/graphql-react';
import { LoadBlocker, TabWithBadge, ToolbarContainer } from '@orthly/ui';
import type { Theme } from '@orthly/ui-primitives';
import {
    FlossPalette,
    createStyles,
    makeStyles,
    Grid,
    IconButton,
    Tab,
    Tabs,
    RefreshIcon,
} from '@orthly/ui-primitives';
import { QCItemChecklist, useFeatureFlag, useQCItemChecklistProps } from '@orthly/veneer';
import React from 'react';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        topSection: {
            [theme.breakpoints.down('lg')]: {
                display: 'none',
            },
        },
        root: {
            flexDirection: 'column',
            background: FlossPalette.TAN,
            borderLeft: `1px solid ${FlossPalette.DARK_TAN}`,
            flexWrap: 'nowrap',
            [theme.breakpoints.down('lg')]: {
                height: `calc(100vh - ${HORIZONTAL_NAV_HEIGHT})`,
                overflow: 'scroll',
            },
            [theme.breakpoints.down('sm')]: {
                height: 'calc(100vh - 112px)',
                overflow: 'scroll',
            },
        },
        titleSection: {
            justifyContent: 'space-between',
            [theme.breakpoints.down('lg')]: {
                display: 'none',
            },
        },
    }),
);

interface OrderDetailChatProps {
    order?: LabsGqlOrder;
    refetchOrder: SingleLabOrderQueryHookResult['refetch'];
    loading: boolean;
    mobileView?: boolean;
    chatMessages: LabsGqlChatMessageDtoFragment[];
    refetchMessages: () => Promise<any>;
}

export const OrderDetailChat: React.FC<OrderDetailChatProps> = props => {
    const classes = useStyles();
    const { order, mobileView, chatMessages, refetchMessages } = props;
    const { orderId } = useParams<{ orderId: string }>();
    const { value: maintenanceBannerPractice } = useFeatureFlag('maintenanceBannerPractice');
    const refetchOrder = useOrderRefetch();

    const qcProps = useQCItemChecklistProps(order?.id ?? orderId);
    const [tabValue, setTabValue] = React.useState<'timeline' | 'qc' | 'labtrac'>('timeline');
    const qcItemCount = qcProps.items.filter(i => !i.complete).length;

    const inputPlaceholder = 'Leave a message';

    const contentHeight = useCalculateContentHeight({
        maintenanceBannerPractice: !!maintenanceBannerPractice?.length,
    });

    if (mobileView) {
        // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
        // eslint-disable-next-line no-nested-ternary
        return order ? (
            <LabOrderChat
                order={order}
                chatMessages={chatMessages}
                refetchChatMessages={refetchMessages}
                inputPlaceholder={inputPlaceholder}
            />
        ) : null;
    }

    return (
        <Grid container style={{ height: contentHeight }} className={classes.root}>
            <ToolbarContainer className={classes.titleSection}>
                <Tabs
                    indicatorColor={'secondary'}
                    textColor={'inherit'}
                    value={tabValue}
                    onChange={(_, value) => setTabValue(value)}
                >
                    <Tab value={'timeline'} label={<TabWithBadge count={chatMessages.length} label={'Timeline'} />} />
                    <Tab value={'qc'} label={<TabWithBadge count={qcItemCount} label={'QC'} />} />
                </Tabs>
                <LoadBlocker blocking={props.loading} ContainerProps={{ style: { width: 'auto' } }}>
                    <IconButton
                        size={'small'}
                        onClick={() => {
                            if (order) {
                                refetchOrder(order.id);
                            }
                            void refetchMessages();
                        }}
                    >
                        <RefreshIcon />
                    </IconButton>
                </LoadBlocker>
            </ToolbarContainer>
            {tabValue === 'timeline' && order && (
                <LabOrderChat
                    order={order}
                    chatMessages={chatMessages}
                    refetchChatMessages={refetchMessages}
                    inputPlaceholder={inputPlaceholder}
                />
            )}
            {tabValue === 'qc' && <QCItemChecklist {...qcProps} />}
        </Grid>
    );
};
