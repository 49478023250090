import { AnalyticsClient } from '../../../analytics/analyticsClient';
import { type ScannedOrderDetails } from './useScannedOrderDetailsQuery.graphql';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { apolloErrorMessage } from '@orthly/ui';
import { useSnackbar } from 'notistack';

interface BatchOrderShipmentsAndRecordPackingCompletedProps {
    scannedOrders: ScannedOrderDetails[];
    setScannedOrders: (orders: ScannedOrderDetails[]) => void;
    refetchManifest: () => void;
    setUnprintedLabelUrl: (url: string) => void;
}

const BatchOrderShipmentsAndRecordPackingCompletedMutation = graphql(`
    mutation BatchOrderShipmentsAndRecordPackingCompleted($labOrderIds: [String!]!) {
        batchOrderShipmentsAndRecordPackingCompleted(labOrderIds: $labOrderIds) {
            batching_result {
                label_url
                service_level
                tracking_number
                updated_order_count
            }
            packing_success
        }
    }
`);

export function useBatchOrderShipmentsAndRecordPackingCompleted({
    scannedOrders,
    setScannedOrders,
    refetchManifest,
    setUnprintedLabelUrl,
}: BatchOrderShipmentsAndRecordPackingCompletedProps) {
    const { enqueueSnackbar } = useSnackbar();

    const [createBatchAndRecordPackingCompleteMtn, { loading }] = useMutation(
        //the fn to call inline
        BatchOrderShipmentsAndRecordPackingCompletedMutation, //doc
        {
            fetchPolicy: 'no-cache',
            onError: error => {
                enqueueSnackbar(apolloErrorMessage(error), { variant: 'error' });
            },
            onCompleted: ({ batchOrderShipmentsAndRecordPackingCompleted: result }) => {
                void refetchManifest();
                setScannedOrders([]);

                // Should rarely, if ever, happen
                if (!result.packing_success) {
                    const message = `Batching likely succeeded, but we FAILED to mark orders as packing-complete.`;
                    enqueueSnackbar(message, { variant: 'error' });
                    return;
                }

                setUnprintedLabelUrl(result.batching_result.label_url);
                const message = `${result.batching_result.updated_order_count} orders were batched and marked packing-complete. Please print label`;
                enqueueSnackbar(message, { variant: 'success' });

                scannedOrders.forEach(order => {
                    AnalyticsClient.track('Lab - Portal - Order Added to Batch', {
                        $groups: { order: order.orderId },
                        trackingNumber: result.batching_result.tracking_number,
                    });
                });
            },
        },
    );

    const createBatchAndRecordPackingComplete = () => {
        if (loading) {
            enqueueSnackbar('Batching already in-progress');
        } else {
            void createBatchAndRecordPackingCompleteMtn({
                variables: { labOrderIds: scannedOrders.map(o => o.orderId) },
            });
        }
    };

    return { loading, createBatchAndRecordPackingComplete };
}
