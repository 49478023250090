import { makeStyles, createStyles } from '@orthly/ui-primitives';
import React from 'react';
import ReactDOM from 'react-dom';

export type PrintableOrientation = 'landscape' | 'portrait';

const setOrientation = (orientation?: PrintableOrientation) => {
    if (!orientation) {
        return;
    }
    const style = document.createElement('style');
    style.innerHTML = orientation === 'landscape' ? `@page {size: letter landscape}` : `@page {size: portrait}`;
    style.id = 'print-orientation';
    document.head.appendChild(style);
};

const useStyles = makeStyles(() =>
    createStyles({
        domPortal: {
            position: 'relative',
            top: '-100vh',
            left: '8rem',
            '@media print': {
                top: '0',
                left: '0rem',
                zIndex: 1000000,
            },
        },
    }),
);

interface IPrintableSlipProps {
    openPrintWindow: boolean;
    onPrintComplete?: () => void;
    orientation?: PrintableOrientation;
    children?: React.ReactNode;
}

/**
 * Retrieves the relevant data for orders and renders Printable Slip
 * that can be printed by the manufacturer and sent to the practice
 * @param openPrintWindow {boolean} whether or not to open the print window when the component renders
 * @param onPrintComplete any custom logic after printing
 * @param children Component to render within printable slip
 * @returns Rendered Printable Slip
 */
export const PrintableSlip: React.VFC<IPrintableSlipProps> = ({
    openPrintWindow,
    onPrintComplete,
    orientation,
    children,
}) => {
    const classes = useStyles();

    React.useEffect(() => {
        setOrientation(orientation);
        return () => {
            const child = document.getElementById('print-orientation');
            child?.parentNode?.removeChild(child);
        };
    }, [orientation]);

    // See: https://stackoverflow.com/questions/55565444/how-to-register-event-with-useeffect-hooks
    React.useEffect(() => {
        const rootEl = document.getElementById('root');
        if (rootEl) {
            // When component mounts, add class used to hide nav & menus from the app during print
            rootEl.classList.add('lab-slip-active');

            // When component unmounts, remove class used to hide nav & menus from the app during print
            return () => {
                rootEl.classList.remove('lab-slip-active');
            };
        }
    }, []);

    React.useEffect(() => {
        window.onafterprint = onPrintComplete ?? null;
    }, [onPrintComplete]);

    React.useEffect(() => {
        if (openPrintWindow) {
            window.print();
        }
    }, [openPrintWindow]);

    return ReactDOM.createPortal(
        <div id={'printable-slip'} className={classes.domPortal}>
            {children}
        </div>,
        document.body,
    );
};
