import { ActionCard } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

function getHexEmbedLink(orderNumber: string): string {
    return `https://app.hex.tech/99fa47a2-e436-4bc7-9258-7cfb103b9003/app/b626832c-d55e-45a1-9b70-06e97f2d1752/latest?embedded=true&embeddedStaticCellId=8fe9a843-f4ba-4f40-a73d-de819ad66105&_scan_input=%22${orderNumber}%22`;
}

export const PrepPageBatchInstructionsAndHex: React.VFC<{
    orderNumber: string;
    shouldPackSolo: boolean;
    shouldHoldback: boolean;
    shelfLabel: string;
    arrivedFrom: 'QC' | 'overseas';
}> = props => {
    const { orderNumber, shelfLabel, shouldPackSolo, shouldHoldback } = props;
    const [displayEmbed, setDisplayEmbed] = React.useState<boolean>(!shouldPackSolo);
    const batchTodayMessage = shouldPackSolo ? 'Ready to pack solo!' : 'Eligible for Batching';

    const holdbackAwareShelfLabel = shouldHoldback ? `${shelfLabel} - HOLDBACK` : shelfLabel;

    const batchTodayActionCard = (
        <ActionCard
            variant={shouldPackSolo ? 'info' : 'alert'}
            title={`${batchTodayMessage} - ${holdbackAwareShelfLabel}`}
            subtitle={shouldPackSolo ? 'Pack solo' : `Store on ${holdbackAwareShelfLabel}`}
            style={{ minHeight: 'unset', padding: 8, margin: '0 0 8px' }}
            primaryAction={{
                onClick: () => setDisplayEmbed(c => !c),
                buttonVariant: 'nav',
                text: displayEmbed ? 'Hide Pans' : 'Show Pans',
            }}
        />
    );

    return (
        <Grid container style={{ paddingBottom: 8 }}>
            {batchTodayActionCard}
            {displayEmbed && (
                <Grid container>
                    <iframe src={getHexEmbedLink(orderNumber)} style={{ width: '100%', height: 300, border: 'none' }} />
                </Grid>
            )}
        </Grid>
    );
};
