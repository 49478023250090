import React from 'react';

export const useAnchor = (setOpen?: (open: boolean) => void) => {
    const [anchor, setAnchor] = React.useState<HTMLElement | null>(null);
    return [
        anchor,
        React.useCallback(
            (anchor: HTMLElement | null) => {
                setAnchor(anchor);
                setOpen && setOpen(!!anchor);
            },
            [setAnchor, setOpen],
        ),
    ] as [HTMLElement | null, (anchor: HTMLElement | null) => void];
};
