import { type ApolloError, type MutationFunction, useMutation as useApolloMutation } from '@apollo/client';
import { graphql, type CreateManufacturingBookingsMutation } from '@orthly/graphql-inline-react';

const Mutation = graphql(`
    mutation CreateManufacturingBookings(
        $bookingAction: String!
        $department: String!
        $isOvertime: Boolean!
        $machineId: String
        $orderId: String!
        $stages: [String!]!
    ) {
        createManufacturingBookings(
            bookingAction: $bookingAction
            department: $department
            isOvertime: $isOvertime
            machineId: $machineId
            orderId: $orderId
            stages: $stages
        ) {
            id
            bookingTime
            orderNumber
            stack
            stage
        }
    }
`);

export type MutationResult = CreateManufacturingBookingsMutation['createManufacturingBookings'];

export type SubmitArgs = {
    bookingAction: string;
    department: string;
    isOvertime: boolean;
    machineId: string | undefined;
    orderId: string;
    stages: string[];
};

type ErrorHandler = (args: SubmitArgs, error: ApolloError) => void;
type SuccessHandler = (args: SubmitArgs, result: MutationResult) => void;

export function submit(
    mutation: MutationFunction<CreateManufacturingBookingsMutation, any>,
    onError: ErrorHandler,
    onSuccess: SuccessHandler,
    variables: SubmitArgs,
): void {
    void mutation({ variables })
        .then(res => onSuccess(variables, res.data?.createManufacturingBookings))
        .catch(err => onError(variables, err));
}

export function useMutation(onError: ErrorHandler, onSuccess: SuccessHandler) {
    const [mutation, { loading }] = useApolloMutation(Mutation, { fetchPolicy: 'no-cache' });

    const submit_ = (args: SubmitArgs) => submit(mutation, onError, onSuccess, args);

    return { loading, submit: submit_ };
}
