import { useFirebaseFileDownload } from '../../hooks';
import type { UserRole } from '../../util';
import { CopyToClipboard } from '../CopyToClipboard';
import { useAnchor } from '../TaskButtons/useAnchor';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { usePatientPortalLinkMutation } from '@orthly/graphql-react';
import { LabsGqlDesignOrderAlignerTreatmentPlanDoctorReviewStatus } from '@orthly/graphql-schema';
import { UserRoleUtil } from '@orthly/shared-types';
import { ExpandIcon, FlossPalette } from '@orthly/ui';
import { Button, Grid, IconButton, Menu, MenuItem } from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';

const VeneerAlignerTreatmentPlanTitleVersionSelector_Fragment = graphql(`
    fragment VeneerAlignerTreatmentPlanTitleVersionSelector_Fragment on DesignOrderAlignerTreatmentPlanDTO {
        created_at
        version_number_practice
        doctor_review {
            status
        }
    }
`);

const VeneerAlignerTreatmentPlanTitleActions_Fragment = graphql(`
    fragment VeneerAlignerTreatmentPlanTitleActions_Fragment on DesignOrderAlignerTreatmentPlanDTO {
        manufacturing_files {
            converted_gcs_path
        }
        ...VeneerAlignerTreatmentPlanTitleVersionSelector_Fragment
    }
`);

export const aligner_treatment_plan_version_title = (
    treatment_plan_fragment: FragmentType<typeof VeneerAlignerTreatmentPlanTitleVersionSelector_Fragment>,
) => {
    const treatment_plan = getFragmentData(
        VeneerAlignerTreatmentPlanTitleVersionSelector_Fragment,
        treatment_plan_fragment,
    );

    return `V${treatment_plan.version_number_practice} - ${moment(treatment_plan.created_at).format(`MMM Do`)}`;
};

function aligner_treatment_plan_is_modified_by_doctor(
    treatment_plan_fragment: FragmentType<typeof VeneerAlignerTreatmentPlanTitleVersionSelector_Fragment> | null,
) {
    const treatment_plan = getFragmentData(
        VeneerAlignerTreatmentPlanTitleVersionSelector_Fragment,
        treatment_plan_fragment,
    );
    return treatment_plan && 'modified_by_doctor' in treatment_plan && !!treatment_plan.modified_by_doctor;
}

export const AlignerTreatmentPlanTitleActions: React.VFC<{
    orderId: string;
    treatmentPlanFragment: FragmentType<typeof VeneerAlignerTreatmentPlanTitleActions_Fragment> | null;
    patientName: string;
    userRole: UserRole;
    setIsFullscreen: (open: boolean) => void;
    is_shareable: boolean;
}> = props => {
    const { treatmentPlanFragment, userRole, patientName, orderId, setIsFullscreen, is_shareable } = props;

    const treatmentPlan = getFragmentData(VeneerAlignerTreatmentPlanTitleActions_Fragment, treatmentPlanFragment);

    const [createPatientPortalLink] = usePatientPortalLinkMutation({
        variables: { order_id: orderId, version_id: null },
    });
    const getText = React.useCallback(
        async () => (await createPatientPortalLink()).data?.patient_portal_link,
        [createPatientPortalLink],
    );

    const canDownloadPlanStls = !!treatmentPlan?.manufacturing_files.converted_gcs_path;
    const downloadPlanSTLs = useFirebaseFileDownload(
        treatmentPlan?.manufacturing_files.converted_gcs_path ?? ``,
        `${patientName} Treatment Plan.zip`,
    );

    const [download_menu_anchor, set_download_menu_anchor] = useAnchor();

    const optional_version_suffix = treatmentPlan ? ` (${aligner_treatment_plan_version_title(treatmentPlan)})` : ``;

    return (
        <>
            {is_shareable && (
                <span>
                    <CopyToClipboard labels={{ Ready: `Share with patient` }} source={getText} />
                </span>
            )}

            {canDownloadPlanStls && (
                <Button
                    variant={`ghost`}
                    startIcon={'DownloadIcon'}
                    endIcon={'KeyboardArrowDownIcon'}
                    onClick={ev => set_download_menu_anchor(ev.currentTarget)}
                >
                    Download
                </Button>
            )}
            <Menu
                open={!!download_menu_anchor}
                anchorEl={download_menu_anchor}
                onClose={() => set_download_menu_anchor(null)}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            >
                {(UserRoleUtil.is_internal(userRole) || UserRoleUtil.is_lab(userRole)) && (
                    <MenuItem disabled={!canDownloadPlanStls} onClick={() => downloadPlanSTLs.execute()}>
                        Download treatment plan{optional_version_suffix} STLs
                    </MenuItem>
                )}
            </Menu>
            {canDownloadPlanStls && (
                <IconButton onClick={() => setIsFullscreen(true)} title={`Fullscreen Viewer`}>
                    <ExpandIcon />
                </IconButton>
            )}
        </>
    );
};

export const AlignerTreatmentPlanTitleVersionSelector: React.VFC<{
    treatment_plan_fragments: readonly FragmentType<typeof VeneerAlignerTreatmentPlanTitleVersionSelector_Fragment>[];
    treatment_plan_index: number;
    set_treatment_plan_index: (treatment_plan_index: number) => unknown;
}> = ({ treatment_plan_fragments, treatment_plan_index, set_treatment_plan_index }) => {
    return (
        <Grid container item style={{ backgroundColor: FlossPalette.WHITE, padding: `0 0 0 4px` }}>
            {treatment_plan_fragments.map((treatment_plan_fragment, index) => {
                const treatment_plan = getFragmentData(
                    VeneerAlignerTreatmentPlanTitleVersionSelector_Fragment,
                    treatment_plan_fragment,
                );
                return (
                    <Button
                        key={index}
                        variant={index === treatment_plan_index ? `primary` : `ghost`}
                        onClick={() => set_treatment_plan_index(index)}
                        disabled={index === treatment_plan_index}
                        style={{ margin: `4px 4px 4px 0`, padding: `0 8px`, opacity: 1 }}
                        size={`small`}
                    >
                        {aligner_treatment_plan_version_title(treatment_plan_fragment)}
                        {aligner_treatment_plan_is_modified_by_doctor(treatment_plan_fragment ?? null) && <b>*</b>}
                        {treatment_plan?.doctor_review?.status ===
                            LabsGqlDesignOrderAlignerTreatmentPlanDoctorReviewStatus.Approved && ` (Approved)`}
                    </Button>
                );
            })}
        </Grid>
    );
};
