import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import type { LabsGqlPatientDto } from '@orthly/graphql-schema';
import { DateUtils } from '@orthly/shared-types';
import type { SimpleAutocompleteOption } from '@orthly/ui';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

// This minimal version of the LabsGqlLabOrderFragment is needed to maintain compatibility with Chairside.
export interface SearchAutocompleteOrder
    extends Pick<LabsGqlLabOrderFragment, 'id' | 'created_at' | 'patient' | 'status' | 'doctor_name' | 'items_v2'> {}

export interface SearchAutocompletePatient
    extends Pick<LabsGqlPatientDto, 'id' | 'first_name' | 'last_name' | 'gender' | 'birthday'> {}

export interface ItemSearchControls {
    search: string | undefined;
    setSearch: (search?: string) => any;
}

interface ItemSearchResults {
    search: string | undefined;
    setSearch: (search?: string) => any;
    skipQuery: boolean;
    loading: boolean;
}

export interface OrderSearchResults extends ItemSearchResults {
    items: SearchAutocompleteOrder[];
}

export interface PatientSearchResults extends ItemSearchResults {
    items: SearchAutocompletePatient[];
}

function useAutocompleteOptionsForOrders(orders: SearchAutocompleteOrder[]): SimpleAutocompleteOption[] {
    return React.useMemo(() => {
        return _.sortBy(orders, o => -new Date(o.created_at).valueOf()).map<SimpleAutocompleteOption>(o => {
            const patientName = `${o.patient.first_name} ${o.patient.last_name}`;
            const dateLabel = `Created ${DateUtils.format(o.created_at, 'MM/DD/YY')}`;
            return { value: o.id, label: `${patientName} (${_.startCase(o.status)}. ${dateLabel})` };
        });
    }, [orders]);
}

function useAutocompleteOptionsForPatients(patients: SearchAutocompletePatient[]): SimpleAutocompleteOption[] {
    return React.useMemo(() => {
        return _.sortBy(patients, p => p.last_name).map<SimpleAutocompleteOption>(p => {
            const patientName = `${p.first_name} ${p.last_name}`;
            const subtitle = `${moment(p.birthday).format('M/D/YYYY')}, ${moment().diff(moment(p.birthday), 'year')} years old, ${p.gender}`;
            return { value: p.id, label: `${patientName} (${subtitle})` };
        });
    }, [patients]);
}

export function useOrderSearchAutocomplete(
    onSelectItemId: (itemId: string) => void,
    useItemSearchResults: (controls?: ItemSearchControls) => OrderSearchResults,
    controls?: ItemSearchControls,
) {
    const { items, search, loading, setSearch, skipQuery: searchDisabled } = useItemSearchResults(controls);
    const options = useAutocompleteOptionsForOrders(items);
    const onInputChange = React.useCallback(
        (value: string | null) => {
            setSearch(value ?? undefined);
        },
        [setSearch],
    );
    const onSelectOption = React.useCallback(
        (value: string | null) => {
            if (value) {
                setSearch(undefined);
                onSelectItemId(value);
            }
        },
        [onSelectItemId, setSearch],
    );
    const onBlurOrFocus = React.useCallback(
        (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const newValue = e.target?.value ? e.target?.value.trim() : undefined;
            if (newValue !== search) {
                setSearch(newValue);
            }
        },
        [search, setSearch],
    );
    const noOptionsText = searchDisabled ? 'Search for orders' : 'No options found';
    return {
        search,
        options,
        onInputChange,
        onSelectOption,
        onBlurOrFocus,
        noOptionsText,
        items,
        loading: loading && !searchDisabled,
    };
}

type UnifiedSearchType = 'order' | 'patient';

interface UnifiedSearchProps {
    searchType: UnifiedSearchType;
    onSelectItemId: (itemId: string) => void;
    useSearchResults: () => OrderSearchResults | PatientSearchResults;
}

export function useUnifiedSearchAutocomplete({ searchType, onSelectItemId, useSearchResults }: UnifiedSearchProps) {
    // Fetch results using the provided hook
    const searchResults = useSearchResults();
    const { items, search, setSearch, skipQuery: searchDisabled, loading } = searchResults;

    const orderOptions = useAutocompleteOptionsForOrders(
        searchType === 'order' ? (items as SearchAutocompleteOrder[]) : [],
    );

    const patientOptions = useAutocompleteOptionsForPatients(
        searchType === 'patient' ? (items as SearchAutocompletePatient[]) : [],
    );

    const options = React.useMemo(() => {
        return searchType === 'order' ? orderOptions : patientOptions;
    }, [orderOptions, patientOptions, searchType]);

    const onInputChange = React.useCallback(
        (value: string | null) => {
            setSearch(value ?? undefined);
        },
        [setSearch],
    );
    const onSelectOption = React.useCallback(
        (value: string | null) => {
            if (value) {
                setSearch(undefined);
                onSelectItemId(value);
            }
        },
        [onSelectItemId, setSearch],
    );
    const onBlurOrFocus = React.useCallback(
        (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const newValue = e.target?.value ? e.target?.value.trim() : undefined;
            if (newValue !== search) {
                setSearch(newValue);
            }
        },
        [search, setSearch],
    );
    const noOptionsText = searchDisabled ? 'Search for patients' : 'No options found';
    return {
        search,
        options,
        onInputChange,
        onSelectOption,
        onBlurOrFocus,
        noOptionsText,
        items,
        loading: loading && !searchDisabled,
    };
}
