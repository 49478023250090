import { HotkeysManager } from '../util/hotkeys';
import { SpotlightSearch } from './SpotlightSearch/SpotlightSearch';
import { SpotlightSearchItem } from './SpotlightSearch/SpotlightSearchItem';
import { useOrderSearchAutocomplete } from '@orthly/dentin';
import { useOrderSearchResults } from '@orthly/graphql-react';
import type { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { DateUtils } from '@orthly/shared-types';
import type { NavigationEntryBottom, SimpleAutocompleteOption } from '@orthly/ui';
import { useDebouncedState } from '@orthly/ui';
import { Tooltip, Text, SearchIcon, Box } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

export interface OrdersSpotlightSearchItemProps extends SimpleAutocompleteOption {
    label: string;
    status: LabsGqlLabOrderStatus;
    doctor_name: string;
    created_at: string;
}

export const OrdersSpotlightSearchItem = (
    listProps: React.HTMLAttributes<HTMLLIElement>,
    props: OrdersSpotlightSearchItemProps,
) => {
    const { label, status, doctor_name, created_at } = props;
    const searchItem = (
        <SpotlightSearchItem
            title={label}
            titleRight={
                <Text color={'GRAY'} style={{ fontSize: 16, textAlign: 'right' }}>
                    {_.startCase(status)}
                </Text>
            }
            details={
                <Box sx={{ display: 'grid', gridTemplateColumns: 'auto auto', gridGap: '8px' }}>
                    <Text variant={'body2'}>{doctor_name}</Text>
                    <Text variant={'body2'} color={'GRAY'}>
                        Placed: {DateUtils.format(created_at, 'MM/DD')}
                    </Text>
                </Box>
            }
        />
    );
    return (
        <li {...listProps} key={props.value || props.label}>
            {searchItem}
        </li>
    );
};

interface OrdersSpotlightSearchProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    openOrder: (orderId: string) => void;
}

export const OrdersSpotlightSearch: React.FC<OrdersSpotlightSearchProps> = ({ open, setOpen, openOrder }) => {
    const {
        search,
        loading,
        items: orders,
        onInputChange,
    } = useOrderSearchAutocomplete(openOrder, useOrderSearchResults);

    // Debounce to avoid hitting the backend on every keystroke.
    const [localSearch, setLocalSearch] = useDebouncedState(search ?? null, onInputChange, 500);

    const setSearch = React.useCallback<(text: string | undefined) => void>(
        text => {
            setLocalSearch(text ?? null);
        },
        [setLocalSearch],
    );

    const onSelectOption = React.useCallback<typeof openOrder>(
        value => {
            onInputChange(null);
            setOpen(false);
            openOrder(value);
        },
        [onInputChange, setOpen, openOrder],
    );

    const onClose = React.useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const options = React.useMemo(() => {
        return _.sortBy(orders, o => -new Date(o.created_at).valueOf()).map<OrdersSpotlightSearchItemProps>(order => ({
            value: order.id,
            label: `${order.patient.first_name} ${order.patient.last_name}`,
            status: order.status,
            created_at: order.created_at,
            doctor_name: order.doctor_name,
        }));
    }, [orders]);
    return (
        <SpotlightSearch<OrdersSpotlightSearchItemProps>
            onSelectOption={onSelectOption}
            setSearch={setSearch}
            search={localSearch ?? undefined}
            options={options}
            open={open}
            noOptionsText={'Type to search...'}
            hotkey={'search'}
            onClose={onClose}
            loading={loading}
            placeholder={'Search for a patient'}
            renderOption={OrdersSpotlightSearchItem}
        />
    );
};

const NavContainer: React.FC = props => {
    return (
        <Tooltip
            title={<span style={{ fontSize: 16 }}>{HotkeysManager.tooltipText('search')}</span>}
            placement={'right'}
            arrow
        >
            <div>{props.children}</div>
        </Tooltip>
    );
};

export function useOrdersSpotlightSearchNavItem(
    open: boolean,
    setOpen: (open: boolean) => void,
): NavigationEntryBottom {
    const toggleOpen = React.useCallback(() => {
        setOpen(!open);
    }, [open, setOpen]);
    useHotkeys(HotkeysManager.keyCombo('search'), toggleOpen, [toggleOpen]);
    return { title: 'Search', active: open, Icon: SearchIcon, onClick: () => setOpen(true), Container: NavContainer };
}
