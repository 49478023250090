/* eslint-disable no-nested-ternary */
import { useFirebaseFileDownload } from '../../hooks/useFirebaseFileDownload.graphql';
import { useShouldShowGuidedWaxupFlow } from './Waxup.util.graphql';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { PracticeScreen } from '@orthly/dentin';
import type { FragmentType } from '@orthly/graphql-inline-react';
import { graphql, getFragmentData } from '@orthly/graphql-inline-react';
import type { VeneerUseOrderRevisionItemsDesign_FragmentFragment } from '@orthly/graphql-inline-react';
import type { LabsGqlOrder, LabsGqlSubmitWaxupReviewMutationVariables } from '@orthly/graphql-operations';
import { useSubmitWaxupReviewMutation } from '@orthly/graphql-react';
import { LabsGqlDesignOrderDoctorReviewStatus, LabsGqlLabOrderWaxupReviewStatus } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import type { SimpleMenuPropsItem } from '@orthly/ui';
import { RootActionDialog, SimpleMenu, useChangeSubmissionFn } from '@orthly/ui';
import type { ButtonProps } from '@orthly/ui-primitives';
import { Button, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

export interface DesignActionProps {
    order: Pick<LabsGqlOrder, 'id' | 'scan_export'>;
    open: boolean;
    setOpen: (open: boolean) => void;
    onSubmit: () => void;
    refetch: () => Promise<void>;
    userRole: string;
    selectedDesign?: VeneerUseOrderRevisionItemsDesign_FragmentFragment;
    CustomButton?: React.FC<ButtonProps>;
}

export interface OrderAdminActionDefinition {
    isVisible: boolean;
    Component: React.ComponentType<DesignActionProps>;
    label: string;
}

interface OrderDesignTopBarProps {
    order: Pick<LabsGqlOrder, 'id' | 'items_v2' | 'scan_export' | 'waxup_status'>;
    userRole: string;
    refetch: () => Promise<void>;
    selectedDesignFragment?: FragmentType<typeof VeneerUseOrderRevisionItemsDesign_Fragment>;
    designRevisionFragments?: FragmentType<typeof VeneerUseOrderRevisionItemsDesign_Fragment>[];
    selectedDesignRevisionId?: string;
    setSelectedDesignRevisionId: (id?: string) => void;
    topbarActions?: OrderAdminActionDefinition[];
}

type DesignLabelFormat = 'date_only' | 'type_and_date' | 'version_idx';

// Converts a design revision into a human readable label.
// The revision may be null if it has been deleted.

export function getDesignRevisionLabel(
    revision: VeneerUseOrderRevisionItemsDesign_FragmentFragment | null,
    idx: number,
    labelFormat: DesignLabelFormat = 'type_and_date',
): string {
    const isApprovedDesign = revision?.doctor_review?.status === LabsGqlDesignOrderDoctorReviewStatus.Approved;
    const isRejectedDesign = revision?.doctor_review?.status === LabsGqlDesignOrderDoctorReviewStatus.Rejected;

    // For version index labels, we just shorten to approved design.
    if (isApprovedDesign && labelFormat === 'version_idx') {
        return 'Approved Design';
    }

    if (labelFormat === 'version_idx' && revision?.is_latest_design) {
        return `Current Design`;
    }

    // For non-approved version index labels, return the actual version number.
    if (!revision || labelFormat === 'version_idx') {
        return `Version ${idx + 1}`;
    }

    const datePart = moment(revision.created_at).format('MM/DD/YYYY hh:mma');

    const base = labelFormat === 'date_only' ? datePart : `${revision.label} ${datePart}`;

    if (isApprovedDesign) {
        return `${base} [Doctor Approved]`;
    }

    if (isRejectedDesign) {
        return `${base} [Doctor Rejected]`;
    }

    return base;
}

export const VeneerUseOrderRevisionItemsDesign_Fragment = graphql(`
    fragment VeneerUseOrderRevisionItemsDesign_Fragment on DesignOrderDesignRevisionDTO {
        id
        label
        source_file_zip_path
        design_source
        is_latest_design
        created_at
        doctor_review {
            status
            created_at
        }
    }
`);

export const useOrderRevisionItems = (
    setSelectedDesignRevisionId: (id?: string) => void,
    userRole: string,
    order: Pick<LabsGqlOrder, 'id' | 'items_v2' | 'waxup_status'>,
    designRevisionFragments?: FragmentType<typeof VeneerUseOrderRevisionItemsDesign_Fragment>[],
    selectedDesignRevisionId?: string,
): SimpleMenuPropsItem[] => {
    const designRevisions = getFragmentData(VeneerUseOrderRevisionItemsDesign_Fragment, designRevisionFragments);

    const enableNewGuidedWaxupFlow = useShouldShowGuidedWaxupFlow(order);
    const navigate = useNavigate();
    // this conditional checks to see if the guided waxup FF has been enabled, if this is the doctor in the practice portal performing the action,
    // and if the waxup is no longer waiting to be either approved or rejected
    const shouldNavigateToGuidedWaxupScreen =
        enableNewGuidedWaxupFlow &&
        userRole === 'practitioner' &&
        order.waxup_status !== LabsGqlLabOrderWaxupReviewStatus.ReadyForReview;

    /*
     * The elements in the revisionMenuItems array are essentially entrypoints into the guided waxup flow since, on click, they send you to a screen
     * in the flow based on whether it is a current or historical design, with historical meaning the design has already been rejected/approved and a new design
     * has been sent back to the doctor. With that being said, we want to exclude the current design in certain scenarios to not allow the doctor to
     * access the current design waxup screen. The current design is always included if the guided waxup FF is disabled. If it's turned on, we only want to
     * include the current design if the user is not the doctor (the ops flow is untouched in the v1 iteration of the guided waxup flow),
     * or if the waxup is ready to be reviewed
     */
    const shouldIncludeCurrentDesign =
        !enableNewGuidedWaxupFlow ||
        userRole !== 'practitioner' ||
        order.waxup_status === LabsGqlLabOrderWaxupReviewStatus.ReadyForReview;

    const onClickRevision = React.useCallback(
        (id: string | null) => (setClosed: () => void) => {
            setSelectedDesignRevisionId(id ?? undefined);
            if (shouldNavigateToGuidedWaxupScreen) {
                navigate(`/${PracticeScreen.guided_waxup}/${order.id}/${id}`);
            }
            setClosed();
        },
        [setSelectedDesignRevisionId, shouldNavigateToGuidedWaxupScreen, navigate, order.id],
    );

    const session = useSession();
    const items = React.useMemo<SimpleMenuPropsItem[]>(() => {
        if (!designRevisions || !designRevisions.length) {
            return [];
        }

        return designRevisions
            .filter(d => {
                return shouldIncludeCurrentDesign || !d.is_latest_design;
            })
            .map<SimpleMenuPropsItem>((revision, idx) => ({
                disabled: selectedDesignRevisionId === revision.id,
                onClick: onClickRevision(revision.id),
                value: revision.id,
                label: getDesignRevisionLabel(
                    revision,
                    idx,
                    session?.organization_type === 'practice' ? 'version_idx' : 'type_and_date',
                ),
            }));
    }, [
        designRevisions,
        onClickRevision,
        selectedDesignRevisionId,
        shouldIncludeCurrentDesign,
        session?.organization_type,
    ]);

    // ordering from most current design to oldest design for guided waxups
    return enableNewGuidedWaxupFlow ? items.reverse() : items;
};

export const OrderDesignRevisions: React.FC<
    Omit<OrderDesignTopBarProps, 'topbarActions' | 'refetch' | 'selectedDesignFragment'>
> = props => {
    const { order, designRevisionFragments, setSelectedDesignRevisionId, selectedDesignRevisionId, userRole } = props;
    const enableNewGuidedWaxupFlow = useShouldShowGuidedWaxupFlow(order);

    const designRevisions = getFragmentData(VeneerUseOrderRevisionItemsDesign_Fragment, designRevisionFragments);

    const items = useOrderRevisionItems(
        setSelectedDesignRevisionId,
        userRole,
        order,
        designRevisionFragments,
        selectedDesignRevisionId,
    );

    if (!items.length || !designRevisions?.length) {
        return null;
    }

    const selectedItem = items.find(item => item.value === selectedDesignRevisionId);
    const versionLabel = selectedItem?.label ?? `Current Design`;
    return (
        <SimpleMenu
            ButtonComponent={({ onClick }) => (
                <Button variant={'text'} onClick={onClick} endIcon={'DropdownIcon'}>
                    {enableNewGuidedWaxupFlow
                        ? 'Version history'
                        : userRole === 'practitioner'
                          ? versionLabel
                          : `See history`}
                </Button>
            )}
            items={items}
        />
    );
};

export const OrderDesignTopActions: React.FC<OrderDesignTopBarProps> = props => {
    const { selectedDesignFragment, order, userRole, refetch, selectedDesignRevisionId, topbarActions = [] } = props;
    const [open, setOpenInner] = React.useState<boolean>(false);
    const [selectedAction, setSelectedAction] = React.useState<number | undefined>(undefined);

    const selectedDesign = getFragmentData(VeneerUseOrderRevisionItemsDesign_Fragment, selectedDesignFragment);

    const setOpen = React.useCallback(
        nextOpen => {
            setOpenInner(nextOpen);
            if (!nextOpen) {
                setSelectedAction(undefined);
            }
        },
        [setOpenInner, setSelectedAction],
    );

    const onSubmit = React.useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    const actions = topbarActions.filter(action => action.isVisible);

    React.useEffect(() => {
        setSelectedAction(undefined);
    }, [userRole, selectedDesign, selectedDesignRevisionId]);

    const ActionComponent = React.useMemo<React.ComponentType<DesignActionProps> | undefined>(() => {
        return selectedAction !== undefined && actions ? actions[selectedAction]?.Component : undefined;
    }, [selectedAction, actions]);

    // Opens up the modal if a component is selected
    React.useEffect(() => {
        if (ActionComponent) {
            setOpen(true);
        }
    }, [ActionComponent, setOpen]);

    if (!actions || !actions.length) {
        return null;
    }

    return (
        <>
            <SimpleMenu
                ButtonComponent={({ onClick }) => (
                    <Button variant={'text'} onClick={onClick} endIcon={'DropdownIcon'}>
                        Actions
                    </Button>
                )}
                items={actions.map((action, idx) => {
                    return {
                        onClick: setClosed => {
                            setSelectedAction(idx);
                            setClosed();
                        },
                        label: action.label,
                    };
                })}
            />

            {ActionComponent && (
                <ActionComponent
                    order={order}
                    userRole={userRole}
                    refetch={refetch}
                    open={open}
                    setOpen={setOpen}
                    onSubmit={onSubmit}
                    selectedDesign={selectedDesign ?? undefined}
                />
            )}
        </>
    );
};

export function useSubmitWaxupReview(onSubmit?: () => Promise<void>) {
    const [submitMtn] = useSubmitWaxupReviewMutation();
    const mtnSubmitter = (variables: LabsGqlSubmitWaxupReviewMutationVariables) => submitMtn({ variables });
    return useChangeSubmissionFn<any, [LabsGqlSubmitWaxupReviewMutationVariables]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Waxup review submitted!', {}],
        onSuccess: async () => {
            if (onSubmit) {
                await onSubmit();
            }
        },
    });
}

export const OrderDesignDownloadAction: React.FC<DesignActionProps> = props => {
    const { open, setOpen, order, onSubmit, selectedDesign } = props;

    const downloadDesignFiles = useFirebaseFileDownload(
        selectedDesign?.source_file_zip_path || '',
        _.last(selectedDesign?.source_file_zip_path?.split('/')) ||
            `${order?.scan_export?.patient_first_name} ${order?.scan_export?.patient_last_name}.zip`,
    );

    const onClick = async () => {
        BrowserAnalyticsClientFactory.Instance?.track('All - Portal - Design Revision Downloaded', {
            $groups: { order: order.id },
            displayLocation: 'order_tab',
            isCurrentDesign: !!selectedDesign?.is_latest_design,
        });
        await downloadDesignFiles.execute();
        onSubmit();
    };

    return (
        <RootActionDialog
            loading={false}
            open={open}
            setOpen={setOpen}
            title={`Download Design`}
            content={
                <Button fullWidth variant={'primary'} onClick={onClick}>
                    Download
                </Button>
            }
            style={{ padding: 0 }}
            buttonText={'Download'}
            CustomButton={() => null}
        />
    );
};

export const OrderDesignTopBar: React.FC<React.ComponentProps<typeof OrderDesignTopActions>> = props => {
    return (
        <Grid container justifyContent={'flex-end'}>
            <OrderDesignTopActions {...props} />
        </Grid>
    );
};
