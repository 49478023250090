import { QRCodeOrderSearch } from '../../manufacturer/components/QcQrInput/QRCodeOrderSearch';
import { type ScannedOrderDetails } from '../hooks/useScannedOrderDetailsQuery.graphql';
import { CreateBatchButton } from './CreateBatchButton';
import { PrintShippingLabelButton } from './PrintShippingLabelButton';
import { ResetPageStateButton } from './ResetPageStateButton';
import { ScannedOrdersList } from './ScannedOrdersList';
import { Grid, styled } from '@orthly/ui-primitives';
import React from 'react';

const StyledFormGroup = styled(Grid)({
    padding: '20px',
});

export interface BatchShipmentsOrderSearchV2Props {
    scannedOrders: ScannedOrderDetails[];
    setScannedOrders: (orders: ScannedOrderDetails[]) => void;
    useFindOrderAndAddToOpenList: (searchString: string) => void;
    unprintedLabelUrl: string | null;
    labelWasOpened: boolean;
    setLabelWasOpened: (wasOpened: boolean) => void;
    resetPageState: () => void;
    createBatchAndRecordPackingComplete: () => void;
}

export const BatchShipmentsOrderSearchV2: React.VFC<BatchShipmentsOrderSearchV2Props> = ({
    scannedOrders,
    setScannedOrders,
    useFindOrderAndAddToOpenList,
    unprintedLabelUrl,
    labelWasOpened,
    setLabelWasOpened,
    resetPageState,
    createBatchAndRecordPackingComplete,
}) => {
    return (
        <Grid>
            {scannedOrders.length > 0 && (
                <ScannedOrdersList scannedOrders={scannedOrders} setScannedOrders={setScannedOrders} />
            )}
            <StyledFormGroup xs={12}>
                <QRCodeOrderSearch
                    label={'Scan QR code'}
                    setActiveSearch={useFindOrderAndAddToOpenList}
                    disabled={!!unprintedLabelUrl}
                />
            </StyledFormGroup>
            <Grid>
                {unprintedLabelUrl ? (
                    <Grid>
                        <PrintShippingLabelButton
                            unprintedLabelUrl={unprintedLabelUrl}
                            setLabelWasOpened={setLabelWasOpened}
                        />
                        {labelWasOpened && <ResetPageStateButton resetPageState={resetPageState} />}
                    </Grid>
                ) : (
                    scannedOrders.length > 0 && (
                        <CreateBatchButton completeBatch={createBatchAndRecordPackingComplete} />
                    )
                )}
            </Grid>
        </Grid>
    );
};
