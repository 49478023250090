import type { ScannedOrderDetails } from '../hooks/useScannedOrderDetailsQuery.graphql';
import { HighlightOffIcon, IconButton, ListItem } from '@orthly/ui-primitives';
import { useSnackbar } from 'notistack';
import React from 'react';

interface ScannedOrderDetailRowProps {
    order: ScannedOrderDetails;
    scannedOrders: ScannedOrderDetails[];
    setScannedOrders: (orders: ScannedOrderDetails[]) => void;
}

export const ScannedOrderDetailRow: React.VFC<ScannedOrderDetailRowProps> = ({
    order,
    scannedOrders,
    setScannedOrders,
}) => {
    const { enqueueSnackbar } = useSnackbar();

    const useRemoveFromScannedOrders = React.useCallback(() => {
        setScannedOrders(scannedOrders.filter(o => o.orderId !== order.orderId));
        enqueueSnackbar(`Removed ${order.orderNumber}`);
    }, [enqueueSnackbar, order, scannedOrders, setScannedOrders]);

    return (
        <ListItem>
            {order.orderNumber}: {order.patientName}
            <IconButton size={'small'} onClick={useRemoveFromScannedOrders}>
                <HighlightOffIcon fontSize={'small'} />
            </IconButton>
        </ListItem>
    );
};
