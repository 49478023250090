/* eslint-disable max-lines */
import { AnalyticsClient } from '../../../analytics/analyticsClient';
import {
    isOvernightServiceLevel,
    useIsNewPackagingEnabled,
    useIsPaperUnboxingSlipEnabled,
} from '../batch-shipping.utils';
import { AddToBatchMutationProvider, useAddToBatchMutation } from '../hooks/useAddToBatchMutation.graphql';
import { useBatchShipDestinationAddress } from '../hooks/useBatchShipDestinationAddress.graphql';
import { useNeedsWaxRimInstructions } from '../hooks/useNeedsWaxRimInstructions';
import { BatchShipDestinationSummary } from './BatchShipDestinationSummary.graphql';
import { BatchShipCandidateFragment_Doc } from './BatchShipOrderFragment.graphql';
import { BatchShipSummaryBox } from './BatchShipSummaryBox';
import { PrepPageBatchInstructionsAndHex } from './PrepPageBatchInstructionsAndHex';
import { PrintBatchShippingLabelBtn } from './PrintBatchShippingLabelBtn.graphql';
import { PracticeScreen } from '@orthly/dentin';
import type {
    BatchShipCandidateSearchResultFragment,
    BatchShipCandidateOrderFragment,
} from '@orthly/graphql-inline-react';
import { getFragmentData } from '@orthly/graphql-inline-react';
import { FlossPalette, Link, Grid, Text, Button, Icon } from '@orthly/ui-primitives';
import { useFeatureFlag, UnboxingSlip } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

const CADMUS_MANUFACTURER_ID = '1bdfee90-66d8-413e-8f46-fbfc8e5faab3';
const NEW_PERFECT_MANUFACTURER_ID = 'aa951eba-8a82-4c7b-866c-a7acbd6376ee';
const overseasFulfillmentLabIds = new Set([CADMUS_MANUFACTURER_ID, NEW_PERFECT_MANUFACTURER_ID]);

const SearchedOrderSummarySection: React.VFC<{
    searchedOrder: BatchShipCandidateOrderFragment;
    orderIdsInBatchWithSearchedOrder: string[];
    refetch: () => Promise<unknown>;
    searchLoading: boolean;
    expectedBatchSize: number;
}> = ({ searchedOrder, orderIdsInBatchWithSearchedOrder, refetch, searchLoading, expectedBatchSize }) => {
    // open state and which format
    const [unboxingSlipOpen, setUnboxingSlipOpen] = React.useState<'4x6' | 'letter' | null>(null);
    const newPackingEnabled = useIsNewPackagingEnabled(searchedOrder.practice_id);
    const isOvernight = isOvernightServiceLevel(searchedOrder.service_level ?? '');
    // if the add to batch mutation is loading, we disable the print label button
    const { loading: addingToBatch } = useAddToBatchMutation();
    const paperUnboxingSlipEnabled = useIsPaperUnboxingSlipEnabled(searchedOrder.manufacturer_id);
    const { needsWaxRimInstructions, instructionUrl, onLoadUnboxingSlip } = useNeedsWaxRimInstructions();
    // The user won't be able to see the normal lab-portal order page, so don't link to it
    const isOverseasOrder = overseasFulfillmentLabIds.has(searchedOrder.manufacturer_id);

    // Printing is disabled if we are re-fetching the search or adding to batch, because that can impact the order IDs
    // that we need to record print for
    const printDisabled = addingToBatch || !!unboxingSlipOpen || searchLoading;
    return (
        <BatchShipSummaryBox title={`Search Result${orderIdsInBatchWithSearchedOrder.length > 1 ? ' (in batch)' : ''}`}>
            <Grid container justifyContent={'space-between'}>
                <Text variant={'h6'} style={{ margin: '4px 0' }}>
                    <span style={{ color: FlossPalette.GREEN }}>#{searchedOrder.order_number}</span> - &nbsp;
                    {searchedOrder.patient_name}
                    {!isOverseasOrder && (
                        <Link
                            onClick={() =>
                                window.open(`/${PracticeScreen.lab}/${searchedOrder.lab_order_id}`, '_blank')
                            }
                            style={{ marginLeft: 4, cursor: 'pointer' }}
                        >
                            <Icon icon={'OpenInNew'} style={{ color: FlossPalette.STAR_GRASS, maxHeight: '16px' }} />
                        </Link>
                    )}
                </Text>
                <Text variant={'h6'} style={{ color: isOvernight ? FlossPalette.REVIEW_RED : FlossPalette.GRAY }}>
                    {_.startCase(searchedOrder.service_level).toUpperCase()}
                </Text>
            </Grid>
            <Grid container style={{ paddingTop: 12 }}>
                {newPackingEnabled && (
                    <Button
                        variant={'alert-secondary'}
                        style={{ marginRight: 8 }}
                        onClick={() => setUnboxingSlipOpen('4x6')}
                    >
                        Packaging Slip
                    </Button>
                )}
                {paperUnboxingSlipEnabled && (
                    <Button
                        variant={'secondary'}
                        style={{ marginRight: 8 }}
                        onClick={() => setUnboxingSlipOpen('letter')}
                    >
                        Unboxing slip
                    </Button>
                )}
                {needsWaxRimInstructions && (
                    <Button
                        style={{ marginRight: 8 }}
                        variant={'secondary'}
                        sx={{ color: `${FlossPalette.HIGHLIGHT_BLUE} !important` }}
                        onClick={() => window.open(instructionUrl, '_blank')}
                    >
                        Wax Rim Instructions
                    </Button>
                )}
                {searchedOrder.label_url && (
                    <PrintBatchShippingLabelBtn
                        onSuccess={async () => {
                            if (expectedBatchSize > orderIdsInBatchWithSearchedOrder.length) {
                                AnalyticsClient.track('Labs - Portal - Incomplete Shipping Batch Packed', {
                                    $groups: { order: searchedOrder.lab_order_id },
                                    shipment_service_level: searchedOrder.service_level ?? 'unknown',
                                    searched_order_id: searchedOrder.lab_order_id,
                                    missed_orders_count: expectedBatchSize - orderIdsInBatchWithSearchedOrder.length,
                                    order_ids_in_batch: orderIdsInBatchWithSearchedOrder,
                                });
                            }
                            await refetch();
                        }}
                        orderIdsInBatch={orderIdsInBatchWithSearchedOrder}
                        labelUrl={searchedOrder.label_url}
                        batchTrackingNumber={searchedOrder.tracking_number ?? 'unknown'}
                        disabled={printDisabled}
                    />
                )}
            </Grid>
            {unboxingSlipOpen && (
                <UnboxingSlip
                    layout={unboxingSlipOpen}
                    orderId={searchedOrder.lab_order_id}
                    onPrintComplete={() => {
                        setUnboxingSlipOpen(null);
                        AnalyticsClient.track('Lab - Portal - Unboxing Slip Opened', {
                            $groups: { order: searchedOrder.lab_order_id },
                            displayLocation: 'batch_shipping_page',
                        });
                    }}
                    onLoadComplete={onLoadUnboxingSlip}
                />
            )}
        </BatchShipSummaryBox>
    );
};

interface ShipmentBatch {
    trackingNumber: string;
    containsSearchedOrder: boolean;
    orders: BatchShipCandidateOrderFragment[];
    labelUrl: string | null;
    packingCompletedDate: Date | null;
}

function getIneligibleReason(othersToPracticeToday: number, practiceBatchesEnabled: boolean): string {
    if (othersToPracticeToday === 0) {
        return 'Only order to practice today';
    }
    if (!practiceBatchesEnabled) {
        return 'Practice opted out';
    }
    // if there are others to the practice today, but this one isn't eligible, then all others were packed
    return 'All other candidates have been packed';
}

type BatchesFromQuery = {
    batches: ShipmentBatch[];
    eligibleForBatching: boolean;
    ineligibleReason: string | null;
    orderIdsInBatchWithSearchedOrder: string[];
    allOrdersReadyForBatching: boolean;
    // the count of orders that should be in the final batch (including the searched order)
    expectedBatchSize: number;
};

// groups orders by tracking number into "batches" (orders all being shipped with the same label)
function getBatchesFromQuery(
    query: BatchShipCandidateSearchResultFragment,
    practiceBatchesEnabled: boolean,
): BatchesFromQuery {
    const searchedOrder = getFragmentData(BatchShipCandidateFragment_Doc, query.search_result);
    const shippedOrders = query.shipped_today.map(order => getFragmentData(BatchShipCandidateFragment_Doc, order));
    const byTrackingNumber = _.groupBy(shippedOrders, o => o.tracking_number ?? 'NOT FOUND');
    const rows = Object.entries(byTrackingNumber).map<ShipmentBatch>(([trackingNumber, orders]) => {
        const packingCompletedDates = orders.map(o =>
            o.packing_completed_at ? new Date(o.packing_completed_at) : null,
        );
        return {
            trackingNumber,
            packingCompletedDate: _.max(_.compact(packingCompletedDates)) ?? null,
            containsSearchedOrder: searchedOrder.tracking_number === trackingNumber,
            orders: [...(searchedOrder.tracking_number === trackingNumber ? [searchedOrder] : []), ...orders],
            labelUrl: orders[0]?.label_url ?? null,
        };
    });
    const batches = _.sortBy(rows, r => {
        if (r.containsSearchedOrder) {
            // bring the searched order to the top
            return -1000;
        }
        // sort desc by count of orders with that tracking number. if packing was completed, it goes to the bottom
        return r.packingCompletedDate ? 0 : -r.orders.length;
    });
    const batchWithOrder = batches.find(b => b.containsSearchedOrder);
    const orderIdsInBatchWithSearchedOrder = batchWithOrder?.orders.map(o => o.lab_order_id) ?? [
        searchedOrder.lab_order_id,
    ];
    const orderAlreadyInBatch = !!batchWithOrder;
    const unpackedOrderCount = shippedOrders.filter(o => !o.packing_completed_at).length;

    const unscannedOrderCount = shippedOrders.filter(o => !o.scanned_in_shipping_at).length;
    const allOrdersReadyForBatching = unscannedOrderCount === 0 && query.expected_to_ship_today_count === 0;
    // we add 1 to the count because the searched order is not counted in unpackedOrderCount or expected_to_ship_today
    const totalBatchCandidates = query.expected_to_ship_today_count + unpackedOrderCount + 1;
    // if an order is already in a batch, it displays as eligible
    const eligibleForBatching = orderAlreadyInBatch || (practiceBatchesEnabled && totalBatchCandidates >= 2);
    return {
        batches,
        eligibleForBatching,
        orderIdsInBatchWithSearchedOrder,
        allOrdersReadyForBatching,
        expectedBatchSize: totalBatchCandidates,
        ineligibleReason: eligibleForBatching
            ? null
            : getIneligibleReason(query.expected_to_ship_today_count + shippedOrders.length, practiceBatchesEnabled),
    };
}

interface BatchShipFoundOrderSectionProps {
    query: BatchShipCandidateSearchResultFragment;
    refetchQuery: () => Promise<unknown>;
    searchLoading: boolean;
}

/**
 * Renders the metadata from the old [section to optionally add the searched order into an existing batch]
 */
export const PrepPageFoundOrderSection: React.VFC<BatchShipFoundOrderSectionProps> = ({
    query,
    refetchQuery,
    searchLoading,
}) => {
    const searchedOrder = getFragmentData(BatchShipCandidateFragment_Doc, query.search_result);
    const { value: disabledPracticeIds = [] } = useFeatureFlag('batchShippingDisabledPracticeIds');
    // some practices have opted out of batching, so they are disabled no matter how many orders they have
    const practiceBatchesEnabled = !disabledPracticeIds.includes(searchedOrder.practice_id);
    const { orderIdsInBatchWithSearchedOrder, eligibleForBatching, expectedBatchSize, ineligibleReason } =
        React.useMemo(() => getBatchesFromQuery(query, practiceBatchesEnabled), [query, practiceBatchesEnabled]);

    // Need to track the analytics event in a use effect because the getBatchesFromQuery function can be called multiple times
    React.useEffect(() => {
        AnalyticsClient.track('Labs - Portal - Order Shipping Search Succeeded', {
            order_id: searchedOrder.lab_order_id,
            $groups: { order: searchedOrder.lab_order_id },
            eligible_for_batching: eligibleForBatching,
            ineligible_reason: ineligibleReason,
        });
    }, [searchedOrder.lab_order_id, eligibleForBatching, ineligibleReason]);

    const addressQuery = useBatchShipDestinationAddress(searchedOrder.mailing_address_id);
    const shelfLabel = query.batch_shelf_label;
    const shouldHoldback = query.holdback_until_tomorrow;
    const shouldPlaceOnBatchShelf = eligibleForBatching || shouldHoldback;
    const shouldPackSolo = !shouldPlaceOnBatchShelf;
    const arrivedFrom = overseasFulfillmentLabIds.has(searchedOrder.manufacturer_id) ? 'overseas' : 'QC';

    return (
        <AddToBatchMutationProvider searchedOrderId={searchedOrder.lab_order_id} refetchSearchQuery={refetchQuery}>
            <Grid container>
                {eligibleForBatching && (
                    <PrepPageBatchInstructionsAndHex
                        orderNumber={searchedOrder.order_number}
                        shouldPackSolo={shouldPackSolo}
                        shouldHoldback={shouldHoldback}
                        shelfLabel={shelfLabel}
                        arrivedFrom={arrivedFrom}
                    />
                )}
                <Grid container spacing={3}>
                    <Grid item xs={7}>
                        <SearchedOrderSummarySection
                            searchLoading={searchLoading}
                            refetch={refetchQuery}
                            searchedOrder={searchedOrder}
                            orderIdsInBatchWithSearchedOrder={orderIdsInBatchWithSearchedOrder}
                            expectedBatchSize={expectedBatchSize}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <BatchShipDestinationSummary
                            loading={addressQuery.loading}
                            mailingAddress={addressQuery.mailingAddress}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </AddToBatchMutationProvider>
    );
};
