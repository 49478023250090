import { useLazyQuery } from '@apollo/client';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { apolloErrorMessage } from '@orthly/ui';
import { useSnackbar } from 'notistack';

export interface ScannedOrderDetails {
    orderId: string;
    orderNumber: string;
    patientName: string;
}

const OrderDetailsFragment = graphql(`
    fragment OrderDetails on LabSalesOrderDTO {
        id
        order_number
        patient {
            first_name
            last_name
        }
    }
`);

const ScanQuery = graphql(`
    query GetDetailsForOrder($order_id: String!) {
        getLabSalesOrderById(id: $order_id) {
            ...OrderDetails
        }
    }
`);

export function useScannedOrderDetailsQuery(
    scannedOrders: ScannedOrderDetails[],
    setScannedOrders: (orders: ScannedOrderDetails[]) => void,
) {
    const { enqueueSnackbar } = useSnackbar();

    return useLazyQuery(ScanQuery, {
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        onError: error => {
            enqueueSnackbar(apolloErrorMessage(error), { variant: 'error' });
        },
        onCompleted: data => {
            const orderRaw = getFragmentData(OrderDetailsFragment, data?.getLabSalesOrderById) ?? null;
            if (!orderRaw) {
                enqueueSnackbar('Error fetching details about this order', { variant: 'error' });
                return;
            }
            const order = {
                orderNumber: orderRaw.order_number,
                orderId: orderRaw.id,
                patientName: `${orderRaw.patient.first_name} ${orderRaw.patient.last_name}`,
            };

            setScannedOrders([order, ...scannedOrders]);
        },
    });
}
