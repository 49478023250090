import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';

export const GetEmailPreviewByNotificationLogId_Query = graphql(`
    query GetEmailPreviewByNotificationLogId($notification_log_id: String!) {
        getEmailPreviewByNotificationLogId(notification_log_id: $notification_log_id) {
            emailTemplate
        }
    }
`);

export const useGetEmailPreviewByNotificationLogId = (notification_log_id: string, skip: boolean) => {
    const { data, loading, error, refetch } = useQuery(GetEmailPreviewByNotificationLogId_Query, {
        variables: { notification_log_id },
        skip: !notification_log_id || skip,
    });

    return {
        emailTemplate: data?.getEmailPreviewByNotificationLogId.emailTemplate,
        loading,
        error: error?.message,
        refetch,
    };
};
