export enum PracticeScreen {
    login = 'login',
    onboarding = 'onboarding',
    inbox = 'inbox',
    billing = 'my_practice/billing',
    terms = 'terms',
    my_practice = 'my_practice',
    practice_settings = 'my_practice/settings',
    payment = 'my_practice/payment',
    addresses = 'my_practice/addresses',
    staff = 'my_practice/staff',
    doctors = 'my_practice/doctors',
    pricing_guide = 'my_practice/pricing',
    my_profile = 'my_profile',
    account_settings = 'my_profile/account',
    profile_settings = 'my_profile/settings',
    notifications = 'my_profile/notifications',
    preferences = 'my_profile/preferences',
    notification_logs = 'notifications/logs',
    lab = 'lab', //deprecated user orders instead
    orders = 'orders',
    setup = 'setup',
    appointments = 'appointments',
    insights = 'insights',
    reviews = 'reviews',
    feedback = 'feedback',
    scanbodies = 'scanbodies',
    scanbody_inventory = 'scanbody_inventory',
    registration = 'registration',
    partials_feedback = 'partials_feedback',
    dentures_feedback = 'dentures_feedback',
    try_in_feedback = 'try_in_feedback',
    refab_flow = 'refab_flow',
    guided_waxup = 'guided_waxup',
    support = 'support',
    chat = 'chat',
    search = 'search',
    more = 'more',
    create_account = 'create_account',
    add_phone = 'add_phone',
    payment_methods_and_addresses = 'payment_methods_and_addresses',
    patients = 'patients',
    variant_feedback = 'variant_feedback',
}
