//TODO EPDSCM-2076 move this component to a better shared library
import { orderIdFromQRCodeUrl } from '../../../utils/utils';
import { useBatchOrderShipmentsAndRecordPackingCompleted } from '../hooks/useBatchOrderShipmentsAndRecordPackingCompletedMutation.graphql';
import { type ScannedOrderDetails, useScannedOrderDetailsQuery } from '../hooks/useScannedOrderDetailsQuery.graphql';
import { BatchShipmentsOrderSearchV2, type BatchShipmentsOrderSearchV2Props } from './BatchShipmentsOrderSearchV2';
import { LoadBlocker } from '@orthly/ui';
import { useSnackbar } from 'notistack';
import React from 'react';

export const BatchShipmentsOrderSearchV2_Wrapper: React.VFC<{ refetchManifest: () => void }> = ({
    refetchManifest,
}) => {
    const { enqueueSnackbar } = useSnackbar();

    //These 3 variables determine which actions are available to the user
    const [scannedOrders, setScannedOrders] = React.useState<ScannedOrderDetails[]>([]);
    const [unprintedLabelUrl, setUnprintedLabelUrl] = React.useState<string | null>(null);
    const [labelWasOpened, setLabelWasOpened] = React.useState<boolean>(false);
    const resetPageState = () => {
        setScannedOrders([]);
        setUnprintedLabelUrl(null);
        setLabelWasOpened(false);
    };

    const [orderDetailsQuery] = useScannedOrderDetailsQuery(scannedOrders, setScannedOrders);

    const useFindOrderAndAddToOpenList = React.useCallback(
        (scannedUrl: string) => {
            const orderId = orderIdFromQRCodeUrl(scannedUrl);
            if (!orderId) {
                enqueueSnackbar('Invalid QR Code', { variant: 'error' });
                return;
            }
            if (scannedOrders.find(o => o.orderId === orderId)) {
                enqueueSnackbar('Duplicate Scanned', { variant: 'error' });
                return;
            }

            orderDetailsQuery({ variables: { order_id: orderId } });
        },
        [orderDetailsQuery, enqueueSnackbar, scannedOrders],
    );

    const { loading, createBatchAndRecordPackingComplete } = useBatchOrderShipmentsAndRecordPackingCompleted({
        scannedOrders,
        setScannedOrders,
        refetchManifest,
        setUnprintedLabelUrl,
    });

    const props: BatchShipmentsOrderSearchV2Props = {
        scannedOrders,
        setScannedOrders,
        useFindOrderAndAddToOpenList,
        unprintedLabelUrl,
        labelWasOpened,
        setLabelWasOpened,
        resetPageState,
        createBatchAndRecordPackingComplete,
    };

    return (
        <LoadBlocker blocking={loading} ContainerProps={{ style: { paddingBottom: 12 } }}>
            <BatchShipmentsOrderSearchV2 {...props} />
        </LoadBlocker>
    );
};
