import type { MUITableProps as MUITablePropsIm } from './components/MUITableRoot';
import { MUITableRoot as MUITable } from './components/MUITableRoot';
import { MUITableUtils } from './contexts/MUITableUtils';
import { useMuiTablePropSelector, useMuiTableSelector } from './state';
import { useTableStateContext } from './state/TableStateContext';
import { useTableStateAction } from './state/action-hooks';
import type { Column, MUIDataObj } from './types';

export type MUITableColumn<R extends MUIDataObj> = Column<R>;
export { MUITableFixedSearch } from './components/Toolbars/MUITableFixedSearch';
export { MUITableUtils, MUITable };
export { downloadAsCsv } from './utils/export';
export type MUITableProps<R extends MUIDataObj> = MUITablePropsIm<R>;

// eslint-disable-next-line import/no-default-export -- Lot of dependencies rely on this already, not worth fixing
export default MUITable;

export function useMUITableState() {
    const { state } = useTableStateContext();
    return state;
}
export const useMUITableAction = useTableStateAction;
export const useMUITableSelector = useMuiTableSelector;
export const useMUITablePropSelector = useMuiTablePropSelector;
export * from './types';
export { MUITableRenderRow } from './components/Body/MUITableBody';
export { MUITableBodyRow } from './components/Body/MUITableBodyRow';
export { MUITableSelectCellBase } from './components/Body/MUITableSelectCell';
