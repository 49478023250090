import { useManufacturerIdFromSession } from '../../utils/authorization';
import { ManifestTab } from '../batch-shipments-with-manifest/components/ManifestTab';
import { useEnableBatchShippingManifest } from './batch-shipping.utils';
import { BatchShipmentsOrderSearch } from './components/BatchShipmentsOrderSearch.graphql';
import { ShippingPrepPage } from './components/ShippingPrepPage.graphql';
import { FlossPalette, stylesFactory, Grid, Text, Tab, Tabs } from '@orthly/ui-primitives';
import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';

const useStyles = stylesFactory(() => ({
    pageHeader: {
        borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        height: '64px',
        lineHeight: '64px',
        fontSize: '40px',
        verticalAlign: 'middle',
        paddingLeft: '20px',
        paddingTop: '12px',
    },
}));

/**
 * Root component for the batch shipments pilot at Lehi. It allows fulfillment staff to search for orders and batch them
 * together into the same package / shipping label with other orders that shipped to the practice and address today.
 */
export const BatchShipmentsRoot: React.VFC = () => {
    const classes = useStyles();
    const enableBatchShippingManifest = useEnableBatchShippingManifest();
    const history = useHistory();
    const tabInitialState = history.location.pathname === '/batch-shipments' ? 'prep' : 'manifest';
    const [tab, setTab] = React.useState<'prep' | 'manifest'>(tabInitialState);
    const mfgId = useManufacturerIdFromSession();

    const selectPrepPage = () => {
        history.push('/batch-shipments');
    };

    const selectManifestPage = () => {
        history.push('/batch-shipments/manifest');
    };

    if (enableBatchShippingManifest) {
        return (
            <Grid container direction={'column'}>
                <Grid container className={classes.pageHeader}>
                    <Text variant={'h4'}>Batch shipments</Text>
                </Grid>
                <Tabs value={tab} onChange={(_, value) => setTab(value)}>
                    <Tab label={'Prep'} value={'prep'} onClick={selectPrepPage} />
                    <Tab label={'Manifest'} value={'manifest'} onClick={selectManifestPage} />
                </Tabs>

                <Switch>
                    <Route exact path={'/batch-shipments'}>
                        <ShippingPrepPage />
                    </Route>
                    <Route exact path={'/batch-shipments/manifest'}>
                        {mfgId && <ManifestTab manufacturerId={mfgId} />}
                    </Route>
                </Switch>
            </Grid>
        );
    } else {
        return (
            <Grid container direction={'column'}>
                <Grid container className={classes.pageHeader}>
                    <Text variant={'h4'}>Batch shipments</Text>
                </Grid>
                <BatchShipmentsOrderSearch />
            </Grid>
        );
    }
};
