import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';

export const GetSmsContentsByNotificationLogId_Query = graphql(`
    query GetSmsContentsByNotificationLogId($notification_log_id: String!) {
        getSmsContentsByNotificationLogId(notification_log_id: $notification_log_id) {
            smsContents
        }
    }
`);

export const useGetSmsContentsByNotificationLogId = (notification_log_id: string, skip: boolean) => {
    const { data, loading, error, refetch } = useQuery(GetSmsContentsByNotificationLogId_Query, {
        variables: { notification_log_id },
        skip: !notification_log_id || skip,
    });

    return {
        smsContents: data?.getSmsContentsByNotificationLogId.smsContents,
        loading,
        error: error?.message,
        refetch,
    };
};
