import { Button } from '@orthly/ui-primitives';
import React from 'react';

export const ResetPageStateButton: React.VFC<{ resetPageState: () => void }> = ({ resetPageState }) => {
    return (
        <Button
            variant={'secondary'}
            onClick={() => {
                resetPageState();
            }}
        >
            Start New Batch
        </Button>
    );
};
