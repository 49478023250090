import { useGetSmsContentsByNotificationLogId } from './graphql/useGetSmsContentsByNotificationLogId.graphql';
import { RootActionDialog } from '@orthly/ui';
import { FlossPalette, IconButton, Tooltip, Icon, Text } from '@orthly/ui-primitives';
import React from 'react';

export const NotificationLogsSmsDialog: React.VFC<{ notificationLogId: string; disableButton: boolean }> = ({
    notificationLogId,
    disableButton,
}) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const { smsContents } = useGetSmsContentsByNotificationLogId(notificationLogId, !open);

    return (
        <RootActionDialog
            loading={false}
            title={'Sms Preview'}
            subtitle={
                <div style={{ display: 'flex' }}>
                    <Icon icon={'Error'} style={{ color: FlossPalette.ATTENTION, marginRight: '4px' }} />
                    <Text variant={'body2'}>
                        Be VERY careful when opening links. A lot of them are impersonation links, to open them right
                        click and copy the link then open it in a chrome incognito window.
                    </Text>
                    <Icon icon={'Error'} style={{ color: FlossPalette.ATTENTION, marginLeft: '4px' }} />
                </div>
            }
            CustomButton={({ onClick }) => (
                <Tooltip title={'Sms preview'}>
                    <IconButton onClick={onClick} style={{ color: FlossPalette.STAR_GRASS }} disabled={disableButton}>
                        <Icon
                            icon={'SmsOutlined'}
                            style={{ color: disableButton ? FlossPalette.GRAY : FlossPalette.PRIMARY_FOREGROUND }}
                        />
                    </IconButton>
                </Tooltip>
            )}
            setOpen={setOpen}
            open={open}
            maxWidth={'lg'}
            content={
                <div style={{ padding: '16px', backgroundColor: FlossPalette.DARK_TAN, borderRadius: '4px' }}>
                    <Text variant={'body2'}>{smsContents}</Text>
                </div>
            }
            showCloseButton
        />
    );
};
