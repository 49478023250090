import { LabPortalErrorPage } from '../../../LabPortalErrorPage';
import { LabPortalOrdersPage } from './LabPortalOrdersPage';
import type { TabOption } from './components/LabPortalOrderListTabs/LabPortalOrderListTabs';
import { LabPortalIntakeAutoNestingListView } from './components/LabPortalOrderListViews/intake/LabPortalIntakeAutoNestingListView';
import { LabPortalOrderTabCountsCtxProvider } from './components/providers/LabPortalOrderTabCountsProvider';
import { convertFilterFromHumanReadableQueryParams } from './utils/QueryParamConversionUtils';
import type { LabsGqlLabPortalIntakeOrderListCountsFragment } from '@orthly/graphql-operations';
import { useLabPortalIntakeOrderCountsQuery } from '@orthly/graphql-react';
import { useQueryParam } from '@orthly/ui';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

const TabOptions: TabOption[] = [
    {
        label: 'Auto-Nesting in Progress',
        tabId: 'auto-nesting',
        pathValue: '/nesting/auto-nesting',
        countsQueryKey: 'nestingIntake',
    },
];

export const LabPortalNestingOrdersPage: React.VFC = () => {
    const [filterString, _setFilterString] = useQueryParam('filters');

    const { data: countData, refetch } = useLabPortalIntakeOrderCountsQuery({
        variables: {
            criteria: filterString ? convertFilterFromHumanReadableQueryParams(filterString) : [],
            sort: {},
            columns: TabOptions.map(opt => opt.countsQueryKey),
        },
    });

    const tabOptionsWithCounts = React.useMemo(
        () =>
            TabOptions.map(option => ({
                ...option,
                orderCount:
                    countData?.orders?.[
                        option.countsQueryKey as keyof Omit<LabsGqlLabPortalIntakeOrderListCountsFragment, '__typename'>
                    ],
            })),
        [countData],
    );

    return (
        <LabPortalOrderTabCountsCtxProvider refetch={refetch}>
            <LabPortalOrdersPage
                title={'Nesting Orders'}
                tabOptions={tabOptionsWithCounts}
                disableSort={true}
                customFilterOptions={[{ label: 'Order Number', value: 'order_number' }]}
            >
                <Switch>
                    <Route exact path={'/nesting/auto-nesting'}>
                        <LabPortalIntakeAutoNestingListView />
                    </Route>
                    <Route>
                        <LabPortalErrorPage />
                    </Route>
                </Switch>
            </LabPortalOrdersPage>
        </LabPortalOrderTabCountsCtxProvider>
    );
};
