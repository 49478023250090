import { LoggedInContainerManufacturer } from '../../components/Containers';
import { LabPortalErrorPage } from '../LabPortalErrorPage';
import { BatchShipmentsRoot } from '../batch-shipments/BatchShipmentsRoot.graphql';
import { OrderDetailRoot } from '../labs/order-detail-v2/OrderDetailRoot.graphql';
import { BATCH_SHIPMENTS_ROUTE, BILLING_ROUTE, BOOKING_ROUTE, METAL_PRICING_ROUTE } from './ManufacturerAppRoutes';
import { ManufacturerSidebar } from './ManufacturerSidebar';
import { BookingRoot } from './booking/screens/BookingRoot';
import { MagicTouchFieldEntryConfigDebugger } from './components/MagicTouchFieldEntryConfigDebugger';
import { QcStationRoot } from './components/QcStation/QcStationRoot';
import { ManufacturerBillingRoot } from './pricing/screens/ManufacturerBillingRoot';
import { ManufacturerMetalPricingRoot } from './pricing/screens/ManufacturerMetalPricingRoot';
import { LabPortalDesignPage } from './screens/LabPortalOrderListPages/LabPortalDesignPage';
import { LabPortalIntakeOrdersPage } from './screens/LabPortalOrderListPages/LabPortalIntakeOrdersPage';
import { LabPortalNestedIntakeOrdersPage } from './screens/LabPortalOrderListPages/LabPortalNestedIntakeOrdersPage';
import { LabPortalNestingOrdersPage } from './screens/LabPortalOrderListPages/LabPortalNestingOrdersPage';
import { LabPortalNewOrdersPage } from './screens/LabPortalOrderListPages/LabPortalNewOrdersPage';
import { LabPortalOnHoldPage } from './screens/LabPortalOrderListPages/LabPortalOnHoldPage';
import { LabPortalOverviewPage } from './screens/LabPortalOrderListPages/LabPortalOverviewPage';
import { LabPortalReturnsPage } from './screens/LabPortalOrderListPages/LabPortalReturnsPage';
import { LabPortalShipTodayPage } from './screens/LabPortalOrderListPages/LabPortalShipTodayPage';
import { LabPortalUpdatesPage } from './screens/LabPortalOrderListPages/LabPortalUpdatesPage';
import { PracticeScreen } from '@orthly/dentin';
import { useRefetchOnFocus } from '@orthly/graphql-react';
import { LoadBlocker } from '@orthly/ui';
import { stylesFactory, Grid } from '@orthly/ui-primitives';
import { LabSLipV2Route, OutageBanner, PanLabelRoute, useFeatureFlag } from '@orthly/veneer';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

const useStyles = stylesFactory(theme => ({
    root: {
        position: 'relative',
        maxWidth: '100%',
        [theme.breakpoints.down('lg')]: {
            maxWidth: 'unset',
        },
    },
}));

const ManufacturerOrdersDesktopRoot: React.FC<{ enabledPages: Set<string> }> = props => {
    const { enabledPages } = props;
    const classes = useStyles();
    return (
        <Grid container className={classes.root}>
            <Switch>
                {/* TODO: EPDSCM-618 the on-hold page should link to a router that further defines urls, not the page itself */}
                <Route exact path={[`/on-hold`, `/on-hold/:tabId`]}>
                    {enabledPages.has('OnHold_V2') ? <LabPortalOnHoldPage /> : <LabPortalErrorPage />}
                </Route>
                <Route exact path={[`/new`, `/new/:tabId`]}>
                    {enabledPages.has('New_V2') ? <LabPortalNewOrdersPage /> : <LabPortalErrorPage />}
                </Route>
                <Route exact path={[`/intake`, `/intake/:tabId`]}>
                    {enabledPages.has('Intake') ? <LabPortalIntakeOrdersPage /> : <LabPortalErrorPage />}
                </Route>
                <Route exact path={[`/nested-intake`, `/nested-intake/:tabId`]}>
                    {enabledPages.has('Nested_Intake') ? <LabPortalNestedIntakeOrdersPage /> : <LabPortalErrorPage />}
                </Route>
                <Route exact path={[`/nesting`, `/nesting/:tabId`]}>
                    {enabledPages.has('Nesting') ? <LabPortalNestingOrdersPage /> : <LabPortalErrorPage />}
                </Route>
                <Route exact path={[`/design`, `/design/:tabId`]}>
                    {enabledPages.has('Design_V2') ? <LabPortalDesignPage /> : <LabPortalErrorPage />}
                </Route>
                <Route exact path={[`/updates`, `/updates/:tabId`]}>
                    {enabledPages.has('Updates_V2') ? <LabPortalUpdatesPage /> : <LabPortalErrorPage />}
                </Route>
                <Route exact path={[`/ship-today`, `/ship-today/:tabId`]}>
                    {enabledPages.has('Expedite_V2') ? <LabPortalShipTodayPage /> : <LabPortalErrorPage />}
                </Route>
                <Route exact path={[`/returns/:tabId`]}>
                    {enabledPages.has('Returns_V2') ? <LabPortalReturnsPage /> : <LabPortalErrorPage />}
                </Route>
                {/* The additional orders route is here for QR code links */}
                <Route path={[`/orders/:orderId`, `/${PracticeScreen.lab}/:orderId`]}>
                    <OrderDetailRoot />
                </Route>
                <Route exact path={['/', '']}>
                    <LabPortalOverviewPage enabledPages={enabledPages} />
                </Route>
                <Route>
                    <LabPortalErrorPage />
                </Route>
            </Switch>
        </Grid>
    );
};

interface ManufacturerAppRootProps {
    manufacturerId: string;
}
export const ManufacturerAppRoot: React.FC<ManufacturerAppRootProps> = () => {
    React.useEffect(() => {
        document.title = 'Dandy Labs';
    }, []);

    const useStyles = stylesFactory(() => ({
        fullPage: {
            height: '100vh',
            width: '100vw',
        },
    }));
    const classes = useStyles();

    const { value: allowLabsAutoRefetchOnFocus } = useFeatureFlag('labsAutoRefetchOnFocus');
    useRefetchOnFocus(!!allowLabsAutoRefetchOnFocus);

    const { value: labPortalSidebarLinks, loading: sidebarLoading } = useFeatureFlag('labPortalSidebarLinks');
    const enabledPages = new Set(labPortalSidebarLinks ?? []);

    if (sidebarLoading) {
        // we must load block _before_ the router is executed because the router peforms a redirect
        // if the overview page is not enabled and the default setting for this is false if the flags
        // have not loaded yet
        return (
            <LoadBlocker blocking={true}>
                <div className={classes.fullPage} />
            </LoadBlocker>
        );
    }

    return (
        <>
            <Switch>
                <Route path={`/magic-touch-qr-entry-debug`}>
                    <MagicTouchFieldEntryConfigDebugger />
                </Route>
                <Route path={`/qc-station`}>
                    {enabledPages.has('QCStation') ? (
                        <LoggedInContainerManufacturer>
                            <QcStationRoot />
                        </LoggedInContainerManufacturer>
                    ) : (
                        <LabPortalErrorPage />
                    )}
                </Route>
                <Route path={'/pan-label'}>
                    <PanLabelRoute />
                </Route>
                <Route path={'/lab-slip'}>
                    <LabSLipV2Route />
                </Route>
                <Route>
                    <>
                        <ManufacturerSidebar enabledPages={enabledPages} />
                        <LoggedInContainerManufacturer>
                            <Switch>
                                <Route path={BOOKING_ROUTE}>
                                    <BookingRoot />
                                </Route>
                                <Route path={METAL_PRICING_ROUTE}>
                                    {enabledPages.has('MetalPricing') ? (
                                        <ManufacturerMetalPricingRoot />
                                    ) : (
                                        <LabPortalErrorPage />
                                    )}
                                </Route>
                                <Route path={BILLING_ROUTE}>
                                    {enabledPages.has('Billing') ? <ManufacturerBillingRoot /> : <LabPortalErrorPage />}
                                </Route>
                                <Route path={BATCH_SHIPMENTS_ROUTE}>
                                    <BatchShipmentsRoot />
                                </Route>
                                <Route>
                                    <ManufacturerOrdersDesktopRoot enabledPages={enabledPages} />
                                </Route>
                            </Switch>
                        </LoggedInContainerManufacturer>
                    </>
                </Route>
            </Switch>
            <OutageBanner audience={'lab'} />
        </>
    );
};
