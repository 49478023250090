import { usePanLabels } from '../../../../utils/EnabledFeaturesForLab';
import { useManufacturerIdFromSession } from '../../../../utils/authorization';
import { LabPortalErrorPage } from '../../../LabPortalErrorPage';
import { LabPortalOrdersPage } from './LabPortalOrdersPage';
import { NEW_WITH_DESIGN_ROUTE, NEW_WITHOUT_DESIGN_ROUTE } from './LabPortalRoutes';
import type { TabOption } from './components/LabPortalOrderListTabs/LabPortalOrderListTabs';
import { LabPortalNewNeedsManualEntryListView } from './components/LabPortalOrderListViews/new/LabPortalNewNeedsManualEntryListView';
import { LabPortalNewWithDesignListView } from './components/LabPortalOrderListViews/new/LabPortalNewWithDesignListView';
import { LabPortalNewWithReturnListView } from './components/LabPortalOrderListViews/new/LabPortalNewWithReturnListView';
import { LabPortalNewWithoutDesignListView } from './components/LabPortalOrderListViews/new/LabPortalNewWithoutDesignListView';
import { LabPortalOrderTabCountsCtxProvider } from './components/providers/LabPortalOrderTabCountsProvider';
import { convertFilterFromHumanReadableQueryParams } from './utils/QueryParamConversionUtils';
import type { LabsGqlLabPortalNewOrderListCountsFragment } from '@orthly/graphql-operations';
import { useLabPortalNewOrderCountsQuery } from '@orthly/graphql-react';
import { LabsGqlLabPortalLabOrderSortKey } from '@orthly/graphql-schema';
import { OrderFilterIdEnum } from '@orthly/shared-types';
import { useQueryParam } from '@orthly/ui';
import { compact } from 'lodash';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

const TabOptions: TabOption[] = compact([
    {
        label: 'New with Design',
        tabId: 'with-design',
        pathValue: '/new/with-design',
        countsQueryKey: 'newWithDesign',
    },
    {
        label: 'New without Design',
        tabId: 'without-design',
        pathValue: '/new/without-design',
        countsQueryKey: 'newWithoutDesign',
    },
    {
        label: 'New with Return',
        tabId: 'with-return',
        pathValue: '/new/with-return',
        countsQueryKey: 'newWithReturn',
    },
    {
        label: 'New - Needs Manual Entry',
        tabId: 'needs-manual-entry',
        pathValue: '/new/needs-manual-entry',
        countsQueryKey: 'newManualEntryNeeded',
    },
]);

export const LabPortalNewOrdersPage: React.VFC = () => {
    const manufacturerId = useManufacturerIdFromSession();

    const [filterString, _setFilterString] = useQueryParam('filters');

    const { data: countData, refetch } = useLabPortalNewOrderCountsQuery({
        variables: {
            criteria: filterString ? convertFilterFromHumanReadableQueryParams(filterString) : [],
            sort: {},
            columns: TabOptions.map(opt => opt.countsQueryKey),
        },
    });

    const tabOptionsWithCounts = React.useMemo(
        () =>
            TabOptions.map(option => ({
                ...option,
                orderCount:
                    countData?.orders?.[
                        option.countsQueryKey as keyof Omit<LabsGqlLabPortalNewOrderListCountsFragment, '__typename'>
                    ],
            })),
        [countData],
    );

    const showPanCodes = usePanLabels(manufacturerId);

    return (
        <LabPortalOrderTabCountsCtxProvider refetch={refetch}>
            <LabPortalOrdersPage
                title={'New Orders'}
                tabOptions={tabOptionsWithCounts}
                customSortOptions={[
                    { label: 'Scheduled Start', value: LabsGqlLabPortalLabOrderSortKey.FabricationScheduledStartDate },
                    { label: 'Ship Date', value: LabsGqlLabPortalLabOrderSortKey.ShipByDate },
                    { label: 'Time in List', value: LabsGqlLabPortalLabOrderSortKey.TimeInList },
                ]}
                customFilterOptions={compact([
                    { label: 'Ship Date', value: 'ship_by_date' },
                    { label: 'Fabrication Start Date', value: OrderFilterIdEnum.fabrication_start_date },
                    showPanCodes && {
                        label: 'Pan Category',
                        value: OrderFilterIdEnum.lab_pan_category,
                    },
                ])}
            >
                <Switch>
                    <Route exact path={NEW_WITH_DESIGN_ROUTE}>
                        <LabPortalNewWithDesignListView />
                    </Route>
                    <Route exact path={NEW_WITHOUT_DESIGN_ROUTE}>
                        <LabPortalNewWithoutDesignListView />
                    </Route>
                    <Route exact path={'/new/with-return'}>
                        <LabPortalNewWithReturnListView />
                    </Route>
                    <Route exact path={`/new/needs-manual-entry`}>
                        <LabPortalNewNeedsManualEntryListView />
                    </Route>
                    <Route>
                        <LabPortalErrorPage />
                    </Route>
                </Switch>
            </LabPortalOrdersPage>
        </LabPortalOrderTabCountsCtxProvider>
    );
};
