import { useManifestQuery } from '../hooks/useManifestQuery.graphql';
import { BatchShipmentsOrderSearchV2_Wrapper } from './BatchShipmentsOrderSearchV2_Wrapper';
import { ManifestTable } from './ManifestTable';
import { List, ListItem, Grid } from '@orthly/ui-primitives';
import React from 'react';

export const ManifestTab: React.VFC<{ manufacturerId: string }> = ({ manufacturerId }) => {
    const { batches, loading: manifestIsLoading, refetch: refetchManifest } = useManifestQuery(manufacturerId);

    return (
        <div>
            <Grid style={{ padding: '0 8px' }}>
                Instructions:
                <List>
                    <ListItem> Scan the QR codes for every order in the batch</ListItem>
                    <ListItem> Use the "X" button to remove one if you made a mistake</ListItem>
                    <ListItem>
                        Click "mark batch as packed" when you are sure you have all the right orders. Once you click
                        this, you can no longer change the batch.
                    </ListItem>
                    <ListItem>
                        Refresh the page at any point to start the batch over and/or refresh the manifest
                    </ListItem>
                </List>
                <BatchShipmentsOrderSearchV2_Wrapper refetchManifest={refetchManifest} />
            </Grid>
            <ManifestTable batches={batches} loading={manifestIsLoading} refetchManifest={refetchManifest} />
        </div>
    );
};
