import { Button } from '@orthly/ui-primitives';
import React from 'react';

export const CreateBatchButton: React.VFC<{ completeBatch: () => void }> = ({ completeBatch }) => {
    return (
        <Button
            variant={'primary'}
            onClick={() => {
                completeBatch();
            }}
        >
            Mark Batch as Packed
        </Button>
    );
};
