import { type ManifestBatch } from '../hooks/useManifestQuery.graphql';
import { MUITable, type MUITableColumn } from '@orthly/mui-table';
import { LoadBlocker } from '@orthly/ui';
import React from 'react';

const ManifestRootColumns: MUITableColumn<ManifestBatch>[] = [
    { name: 'Practice Name', render: 'practiceName', filter: false },
    { name: 'Order Count', render: 'orderCount', filter: false },
    { name: 'Ship Speed', render: 'shipSpeed', filterOptions: { type: 'dropdown' } },
    {
        name: 'Order Numbers ',
        filter: false,
        sort: false,
        field: 'orderNumbers',
        render: batch => batch.orderNumbers.map((num: string) => <p key={num}>{num}</p>),
    },

    // TODO(EPDSCM-2144 ): This is probably only relevant for Lehi
    { name: 'Shelf', render: 'primaryShelf', filterOptions: { type: 'multiselect', exact: true } },

    { name: 'Shelf + Bin', render: 'shelfLocation', filterOptions: { type: 'multiselect' } },
];

interface ManifestTableProps {
    batches: ManifestBatch[];
    loading: boolean;
    refetchManifest: () => void;
}

export const ManifestTable: React.VFC<ManifestTableProps> = ({ batches, loading, refetchManifest }) => {
    return (
        <LoadBlocker blocking={loading} ContainerProps={{ style: { paddingBottom: 12 } }}>
            <MUITable<ManifestBatch>
                title={'Batch Shipping Manifest'}
                columns={ManifestRootColumns}
                data={batches}
                loading={loading}
                actions={{
                    global: [
                        {
                            tooltip: 'Refresh',
                            disabled: loading,
                            icon: 'refresh',
                            onClick: () => void refetchManifest(),
                            position: 'toolbar',
                        },
                    ],
                }}
                rowOptions={{
                    setRowStyle: (_r, idx) =>
                        idx % 2 === 0 ? { backgroundColor: 'white' } : { backgroundColor: 'lightgray' },
                }}
                displayOptions={{
                    elevation: 6,
                    filter: true,
                    sort: true,
                    fixedHeader: true,
                    responsive: 'stacked',
                    fixedSearch: true,
                }}
            />
        </LoadBlocker>
    );
};
