import type { IBaseItemData } from '../types';
import type { IRemovableItem, ICustomFieldSubmission, ITMJItem } from '@orthly/items';
import {
    RETAINER_QUANTITY_METAFIELD_ID,
    BLEACHING_WELLS_TEETH_SELECTION_PREF_ID,
    BLEACHING_WELLS_EXCLUDED_TEETH_PREF_ID,
} from '@orthly/items';

export const TOP_LEVEL_PREFERENCE_FIELDS: { [key: string]: string } = {
    [BLEACHING_WELLS_TEETH_SELECTION_PREF_ID]: 'Teeth Selection',
    [BLEACHING_WELLS_EXCLUDED_TEETH_PREF_ID]: 'Excluded Teeth',
    'night-guard-arch-choice-order-item-meta': 'Arch',
    'ideal-night-guard-height-order-item-meta': 'Ideal Height',
    'soft-night-guard-thickness-order-item-meta': 'Thickness',
    'hard-soft-ng-thickness-order-item-meta': 'Thickness',
    'night-guard-thickness-order-item-meta': 'Thickness',
    'night-guard-occlusal-surface-type-order-item-meta': 'Occlusal Surface Type',
    'night-guard-canine-guidance-order-item-meta': 'Canine Guidance',
    'night-guard-anterior-ramp-order-item-meta': 'Anterior Ramp',
    'retainer-arch-choice-order-item-meta': 'Arch',
    'retainer-lingual-fixed-bonding-pad': 'Retainer Bonding Pad',
    'retainer-lingual-fixed-wire-style': 'Fixed Lingual Retainer Wire Style',
    'tmj-arch-order-item-meta': 'Arch',
    'tmj-splint-material-thickness-order-item-meta': 'Splint Material Thickness',
    'tmj-appliance-material-order-item-meta': 'Appliance Material',
    'tmj-color-order-item-meta': 'Color',
    'tmj-teeth-height-coverage-order-item-meta': 'Teeth Height Coverage',
    'tmj-teeth-coverage-item-meta': 'Teeth Coverage',
    'tmj-arch-separation-distance-order-item-meta': 'Arch Separation Distance',
};

export const QUANTITY_FIELDS = [
    'ng-quantity-night-guard-order-item-meta',
    RETAINER_QUANTITY_METAFIELD_ID,
    'bleaching-tray-quantity-night-guard-order-item-meta',
];

/**
 * Determine if two Removable order items have the same top-level preferences
 * @param item1Prefs Preferences for order item1
 * @param item2Prefs Preferences for order item2
 * @returns {boolean} Whether or top-level preference fields have the same value
 */
export function topLevelPreferencesMatch(
    item1Prefs: Pick<ICustomFieldSubmission, 'field_id' | 'value'>[],
    item2Prefs: Pick<ICustomFieldSubmission, 'field_id' | 'value'>[],
) {
    const filteredPrefs1 = item1Prefs.filter(pref => !!TOP_LEVEL_PREFERENCE_FIELDS[pref.field_id]);
    const filteredPrefs2 = item2Prefs.filter(pref => !!TOP_LEVEL_PREFERENCE_FIELDS[pref.field_id]);

    if (filteredPrefs1.length !== filteredPrefs2.length) {
        return false;
    }

    const preferenceMap = filteredPrefs1.reduce<{ [key: string]: any }>((agg, pref) => {
        return {
            ...agg,
            [pref.field_id]: pref.value,
        };
    }, {});

    return filteredPrefs2.every(pref => preferenceMap[pref.field_id] === pref.value);
}

/**
 * Determine if two Removable order items are similar enough that they can
 * be "merged" into a single item with increased quantity
 * @param item1 Removable order item to attempt to merge
 * @param item2 2nd Removable item to attempt to merge with itemA
 * @returns {boolean} Whether or not the two items are similar enough to be merged
 */
export function removableItemsAreSimilar(item1: IRemovableItem, item2: IRemovableItem) {
    if (item1.unit.unit_type !== item2.unit.unit_type || item1.unit.material !== item2.unit.material) {
        return false;
    }

    return topLevelPreferencesMatch(item1.preference_fields, item2.preference_fields);
}

/**
 * Combine items that share similar data into a single item with increased quantity
 * @param items Removable order items to attempt to group
 * @param comparator An optional function that compares two Removable Items for similarity
 * @returns List of merged removable order items
 */
export function mergeSimilarRemovableItems(
    items?: IRemovableItem[],
    comparator: (itemA: IRemovableItem, itemB: IRemovableItem) => boolean = removableItemsAreSimilar,
): IRemovableItem[] {
    if (!items) {
        return [];
    }
    const mergedItems: IRemovableItem[] = [];
    items.forEach(item => {
        const matchingItem = mergedItems.find(i => item.unit.unit_type === i.unit.unit_type);
        if (matchingItem && comparator(matchingItem, item)) {
            const mergedQuantityField = matchingItem.preference_fields.find(pref =>
                QUANTITY_FIELDS.includes(pref.field_id),
            );
            const quantityField = item.preference_fields.find(pref => QUANTITY_FIELDS.includes(pref.field_id));
            if (mergedQuantityField?.value && quantityField?.value) {
                mergedQuantityField.value =
                    parseInt(`${mergedQuantityField.value}`) + parseInt(`${quantityField.value}`);
                return;
            }
        }
        mergedItems.push(JSON.parse(JSON.stringify(item)));
    });
    return mergedItems;
}

export function mapRemovableItemToLabSlipItemFields(item: IRemovableItem | ITMJItem): IBaseItemData['itemFields'] {
    return [
        {
            key: 'Material',
            value: item.unit.material,
        },
        ...item.preference_fields
            .filter(pref => !!TOP_LEVEL_PREFERENCE_FIELDS[pref.field_id])
            .map(pref => ({
                key: TOP_LEVEL_PREFERENCE_FIELDS[pref.field_id],
                value: String(pref.value),
            })),
    ];
}
